import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Footer(props) {
  return (
    <footer>
      <Typography
        sx={{ paddingTop: "1rem" }}
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.clue2solve.io/">
          Check our Website
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
}
