import * as React from "react";

import { Paper, InputBase, Divider, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchInput(props) {
  const {
    searchedValue,
    onChangeSearchTxt,
    onClearSearchTxt,
    onKeyPressSearch,
    className,
    specificDetails,
  } = props;

  return (
    <Paper
      sx={{
        p: "2px 4px",
        mb: 1,
        display: "flex",
        alignItems: "center",
        width: 400,
        ...className,
        ...specificDetails?.styles?.paper,
      }}
    >
      <SearchIcon
        sx={{ opacity: 0.6, ...specificDetails?.styles?.searchIcon }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        onChange={onChangeSearchTxt}
        onKeyUp={onKeyPressSearch}
        value={searchedValue || ""}
        sx={{ ml: 1, flex: 1 }}
        placeholder={specificDetails?.placeholder ?? "Search"}
      />
      {searchedValue &&
        (specificDetails?.endIc ? (
          specificDetails?.endIc
        ) : (
          <IconButton
            onClick={onClearSearchTxt}
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <CloseIcon />
          </IconButton>
        ))}
    </Paper>
  );
}
