import React, { useState } from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import t from "./helpers/en";
import { NAV_ITEMS } from "./helpers/constants";

import MainLayout from "./pages/Main/Main";
import MinimalLayout from "./pages/Main/MinimalLayout";
import ErrorPage from "./pages/ErrorPage";
import Users from "./pages/Users";
import Login from "./pages/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import Builds from "./pages/ViewBuilds";
import Builders from "./pages/Builders";
import Wizard from "./pages/Wizard";
import Images from "./pages/Images";
import ImageDetails from "./pages/ImageDetails";
import Services from "./pages/Services";
import ServiceAccounts from "./pages/ServiceAccounts";
import GitSecrets from "./pages/GitSecrets";
import Logs from "./pages/Logs";
import ConfigParams from "./pages/ConfigParams";
import Registries from "./pages/Registries";
import ClusterStacks from "./pages/ClusterStacks";
import ClusterStores from "./pages/ClusterStores";
import ManageApps from "./pages/ManageApps";
import ManageDb from "./pages/ManageDb";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#1c7ac1fe",
      activeLink: "#176aa9fe",
      text: "#fff",
    },
  },
});

export default function App() {
  const [currentUser, setCurrentUser] = useState(
    sessionStorage.getItem("token") &&
      jwtDecode(sessionStorage.getItem("token"))
  );

  const onLoginResponse = (response, status, cb) => {
    if (status === 200) {
      //let`s try parse JWT
      const user = jwtDecode(response);

      sessionStorage.setItem("token", response);
      sessionStorage.setItem("userData", JSON.stringify(user));
      setCurrentUser(user);

      cb && cb();
    } else if (status === 401 || status === 403) {
      onLogout();
    }
  };

  const onLogout = (cb) => {
    googleLogout();
    sessionStorage.clear();
    setCurrentUser(null);

    cb && cb();
  };

  const router = createBrowserRouter([
    {
      path: "",
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <ProtectedRoutes />,
          children: [
            {
              path: "/",
              element: (
                <MainLayout
                  navItems={NAV_ITEMS}
                  {...{ onLogout, currentUser }}
                />
              ),
              handle: {
                crumb: () => <Link to="">{t.home}</Link>,
              },
              children: [
                {
                  index: true,
                  element: <ManageApps {...{ currentUser }} />,
                },
                {
                  path: "image/:id",
                  element: <ImageDetails {...{ currentUser }} />,
                },
                {
                  path: "wizard-old",
                  element: <Wizard />,
                  handle: {
                    crumb: () => <Link to="wizard">{t.wizard}</Link>,
                  },
                },
                {
                  path: "builders",
                  element: <Builders />,
                  handle: {
                    crumb: () => <Link to="builders">{t.builders}</Link>,
                  },
                },
                {
                  path: "images",
                  element: <Images />,
                  handle: {
                    crumb: () => <Link to="images">{t.images}</Link>,
                  },
                },
                {
                  path: "service-accounts",
                  element: <ServiceAccounts />,
                  handle: {
                    crumb: () => (
                      <Link to="service-accounts">{t.serviceAccounts}</Link>
                    ),
                  },
                },
                {
                  path: "registries",
                  element: <Registries />,
                  handle: {
                    crumb: () => <Link to="registries">{t.registries}</Link>,
                  },
                },
                {
                  path: "git-secrets",
                  element: <GitSecrets />,
                  handle: {
                    crumb: () => (
                      <Link to="git-secrets">{t.privateRepository}</Link>
                    ),
                  },
                },
                {
                  path: "cluster-stacks",
                  element: <ClusterStacks />,
                  handle: {
                    crumb: () => (
                      <Link to="cluster-stacks">{t.clusterStacks}</Link>
                    ),
                  },
                },
                {
                  path: "cluster-stores",
                  element: <ClusterStores />,
                  handle: {
                    crumb: () => (
                      <Link to="cluster-stores">{t.clusterStores}</Link>
                    ),
                  },
                },
                {
                  path: "services",
                  element: <Services />,
                  handle: {
                    crumb: () => <Link to="services">{t.services}</Link>,
                  },
                },
                {
                  path: "config",
                  element: <ConfigParams />,
                  handle: {
                    crumb: () => <Link to="config">{t.configsAndSecrets}</Link>,
                  },
                },
                {
                  path: "manage-db",
                  element: <ManageDb />,
                  handle: {
                    crumb: () => (
                      <Link to="manage-db">
                        {t.manage} {t.database}
                      </Link>
                    ),
                  },
                },
                {
                  path: "builds",
                  element: <Builds />,
                  handle: {
                    crumb: () => <Link to="builds">{t.builds}</Link>,
                  },
                },
                {
                  path: "logs",
                  element: <Logs />,
                  handle: {
                    crumb: () => <Link to="logs">{t.events}</Link>,
                  },
                },
              ],
            },
            {
              path: "/",
              element: <MinimalLayout />,
              children: [{ path: "/users", element: <Users /> }],
            },
          ],
        },
        // PUBLIC ROUTES
        {
          path: "/login",
          element: (
            <MinimalLayout
              className={{
                width: "fit-content",
                minHeight: "30em",
                display: "flex",
                alignItems: "center",
              }}
            />
          ),
          children: [
            {
              index: true,
              element: <Login {...{ onLoginResponse }} />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <React.StrictMode>
        <RouterProvider {...{ router }} />
      </React.StrictMode>
    </ThemeProvider>
  );
}
