import t from "./en";

import { ManageSearch, Settings } from "@mui/icons-material";

const getApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL;
  } else {
    return process.env.REACT_APP_API_URL;
  }
};

export const API_URL = getApiUrl();

const getAuthApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_AUTH_API_URL;
  } else {
    return process.env.REACT_APP_AUTH_API_URL;
  }
};

export const AUTH_API_URL = getAuthApiUrl() + "/auth";

export const USER_API_URL = getAuthApiUrl();

const getK8sApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_K8S_API_URL;
  } else {
    return process.env.REACT_APP_K8S_API_URL;
  }
};

export const K8S_API_URL = getK8sApiUrl();
export const BYTES = 1024;

export const TEST_ID = "TEST_ID";
export const INITIAL_STATE = { id: TEST_ID }; //used for drag and drop functionality
export const INDUSTRIES = [
  {
    id: "00000000-0000-0000-0000-000000100000",
    name: "Information technology (IT)",
  },
];
export const INPUT_TYPES = [
  { id: "1", name: "SINGLE_SELECT" },
  { id: "2", name: "MULTI_SELECT" },
  { id: "3", name: "SINGLE_LINE_TEXT" },
  { id: "4", name: "MULTI_LINE_TEXT" },
  { id: "5", name: "DATE" },
];

export const USER_SOURCE = [
  { id: "1", name: "LOCAL" },
  { id: "2", name: "GOOGLE" },
  { id: "3", name: "GITHUB" },
  { id: "4", name: "OTHER" },
];

export const USERS_ROLES = [
  { id: "1", name: "SYSTEM" },
  { id: "2", name: "ADMIN" },
  { id: "3", name: "USER" },
  { id: "4", name: "MANAGER" },
];

export const USER_STATUS = [
  { id: "1", name: "Active", enabled: true },
  { id: "2", name: "Inactive", enabled: false },
];

export const NAV_ITEMS = [
  {
    id: "list1",
    label: `${t.manageApplications}`,
    to: "/",
    icon: ManageSearch,
    subItems: [
      {
        label: `${t.images}`,
        to: "/images",
      },
      {
        label: `${t.serviceAccounts}`,
        to: "/service-accounts",
      },
      {
        label: `${t.registries}`,
        to: "/registries",
      },
      {
        label: `${t.privateRepository}`,
        to: "/git-secrets",
      },
      {
        label: `${t.services}`,
        to: "/services",
      },
      {
        label: `${t.configsAndSecrets}`,
        to: "/config",
      },
      {
        label: `${t.manage} ${t.database}`,
        to: "/manage-db",
      },
      {
        label: t.events,
        to: "/logs",
      },
      {
        label: `${t.builds}`,
        to: "/builds",
      },
    ],
  },
  {
    id: "list2",
    label: `${t.managePlatform}`,
    icon: Settings,
    subItems: [
      {
        label: `${t.builders}`,
        to: "/builders",
      },
      {
        label: `${t.clusterStacks}`,
        to: "/cluster-stacks",
      },
      {
        label: `${t.clusterStores}`,
        to: "/cluster-stores",
      },
    ],
  },
];

export const PAGE_SIZE = 100;

export const ROWS_PARAMS_LOGS = (data) => ({
  header: [t.name, t.type, t.operation, t.createdBy, t.createdOn],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.displayName },
    { value: val?.objectType },
    { value: val?.operationType },
    {
      value: val?.createdBy?.familyName
        ? `${val?.createdBy?.familyName} ${val?.createdBy?.givenName}`
        : val?.createdBy?.username,
    },
    { value: val?.createdOn },
  ],
});

export const AUDIT_LOGS = "AUDIT_LOGS";
export const EDIT_ITEM = "EDIT_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const JSON_ITEM = "JSON_ITEM";
export const NEW_ITEM = "NEW_ITEM";
export const PRIVATE = "PRIVATE";
export const PRIVATE_REGISTRY = "PRIVATE_REGISTRY";
export const GIT_NEW_CREDENTIALS = "GIT_NEW_CREDENTIALS";
export const REGISTRY_NEW_CREDENTIALS = "REGISTRY_NEW_CREDENTIALS";
export const EDIT_VARIABLE = "EDIT_VARIABLE";
export const DELETE_VARIABLE = "DELETE_VARIABLE";
export const ADD_VARIABLE = "ADD_VARIABLE";
export const FORCE_BUILD = "FORCE_BUILD";
export const JSON_ADD_VARIABLES = "JSON_ADD_VARIABLES";

export const TYPE_DB = [{ id: "POSTGRES" }, { id: "MYSQL" }];
export const SOURCES_DB = [
  { id: "PLATFORM_HOSTED_DB_SERVER" },
  { id: "PLATFORM_HOSTED_CLUSTER" },
];

export const FALSE = "False";
