import { GET, POST, PUT, DELETE } from "./actions";
import {
  AUTH_API_URL,
  USER_API_URL,
  K8S_API_URL,
  PRIVATE,
  PRIVATE_REGISTRY,
  GIT_NEW_CREDENTIALS,
  REGISTRY_NEW_CREDENTIALS,
} from "./constants";
import t from "./en";

export function newProject(payload, callback) {
  const userRights = payload.selectedUsers?.map((it) => ({
    user: { id: it.id },
    isContributor: it.isContributor,
  }));
  const body = JSON.stringify({
    name: payload.name,
    userRights: userRights,
  });

  POST(
    "/k8s-project/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateProject(payload, callback) {
  const userRights = payload.selectedUsers?.map((it) => ({
    user: { id: it.id },
    isContributor: it.isContributor,
  }));
  const body = JSON.stringify({
    id: payload.id,
    name: payload.name,
    userRights,
  });

  PUT(
    `/k8s-project/${payload.id}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteProject(payload, callback) {
  DELETE(
    `/k8s-project/${payload.id}`,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

//CUSTOMERS
export function getCustomers(callback) {
  GET("/customer/", "", (result, status) => {
    callback(
      status === 200 && result,
      status !== 200 && (result?.message ?? t.errorMsg)
    );
  });
}

export function createCustomer(form, callback) {
  const body = JSON.stringify({
    contactPersonName: form.name,
    contactPersonPhoneNumber: form.phone,
    contactPersonEmail: form.email,
    ...(form.comments ? { comments: form.comments } : {}),
  });

  POST("/customer/", body, (result, status) => {
    callback(status === 200 && result, status !== 200 && result?.message);
  });
}

export function updateCustomer(form, callback) {
  const body = JSON.stringify({
    id: form.id,
    contactPersonName: form.name,
    contactPersonPhoneNumber: form.phone,
    contactPersonEmail: form.email,
    ...(form.comments ? { comments: form.comments } : {}),
  });

  PUT(`/customer/${form.id}`, body, (result, status) => {
    callback(status === 200 && result, status !== 200 && result?.message);
  });
}

export function deleteCustomer(form, callback) {
  DELETE(`/customer/${form.id}`, (result, status) => {
    callback(status === 200 && result, status !== 200 && result?.message);
  });
}

//GOOGLE OAUTH
export function authWithGoogle(payload, callback) {
  POST(
    "/google",
    JSON.stringify(payload),
    (result, status) => {
      if (callback) {
        callback(
          result,
          status,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
      }
    },
    AUTH_API_URL
  );
}

//GITHUB OAUTH
export function authWithGithub(payload, callback) {
  POST(
    "/github",
    JSON.stringify(payload),
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    AUTH_API_URL
  );
}

//LOCAL Login username&pwd
export function authWithCustomLogin(payload, callback) {
  POST(
    "/local",
    JSON.stringify(payload),
    (result, status) => {
      if (callback) {
        callback(
          result,
          status,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
      }
    },
    AUTH_API_URL
  );
}

function downloadFile(blob, fileName) {
  let url = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
}

export function downloadCSV(form) {
  const categories = form?.categories?.map((it) => it.id).join(",");

  GET(`/question/csv`, { categories }, (result, status) => {
    if (status === 200) {
      downloadFile(result, "questions.csv");
    }
  });
}

//USERS
export function getUsers(callback, payload) {
  const endpoint = !payload?.shouldSearchByEmail
    ? "/user/"
    : `/user/email/${payload?.email}`;

  GET(
    endpoint,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    USER_API_URL
  );
}

export function createUser(form, callback) {
  const body = JSON.stringify({
    source: form.source,
    givenName: form.givenName,
    familyName: form.familyName,
    username: form.username,
    email: form.email,
    role: form.role,
    group:
      form.group === "NEW" ? { name: form.group_name } : { id: form.group },
    ...(form.password ? { password: form.password } : {}),
  });

  POST(
    "/user/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    USER_API_URL
  );
}

export function createUserWithGoogle(form, callback) {
  const body = JSON.stringify({
    clientId: form.clientId,
    credential: form.credential,
  });

  POST("/user/google", body, (result, status) => {
    callback(status === 200 && result, status !== 200 && result?.message);
  });
}

export function updateUser(form, callback) {
  const body = JSON.stringify({
    source: form.source,
    id: form.id,
    givenName: form.givenName,
    familyName: form.familyName,
    username: form.username,
    email: form.email,
    role: form.role,
    enabled: !!form.enabled,
    group:
      form.group === "NEW" ? { name: form.group_name } : { id: form.group },
    ...(form.password ? { password: form.password } : {}),
  });

  PUT(
    `/user/${form.id}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    USER_API_URL
  );
}

export function deleteUser(form, callback) {
  DELETE(
    `/user/${form.id}`,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    USER_API_URL
  );
}

//PROJECTS
export function getProjects(callback) {
  GET(
    "/k8s-project/",
    "",
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function getk8sLogs(payload, callback) {
  GET(
    `/k8s-audit-log/${payload.objectName ? `${payload.objectName}/` : ""}${
      payload?.pageNumber
    }/${payload?.pageSize}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getBuilders(callback) {
  GET(
    "/k8s-builder/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newBuild(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    tag: payload.tag,
    serviceAccountName: payload.serviceAccountName,
    clusterStack: {
      name: payload?.clusterStack,
    },
    clusterStore: {
      name: payload?.clusterStore,
    },
    buildpackIds: payload.buildpackIds,
  });

  POST(
    "/k8s-builder/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateBuild(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    tag: payload.tag,
    serviceAccountName: payload.serviceAccountName,
    clusterStack: {
      name: payload?.clusterStack,
    },
    clusterStore: {
      name: payload?.clusterStore,
    },
    buildpackIds: payload.buildpackIds,
  });

  PUT(
    `/k8s-builder/${payload.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteBuilder(name, callback) {
  DELETE(
    `/k8s-builder/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getImages(payload, callback) {
  GET(
    `/k8s-image/${payload?.name ? payload.name : ""}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getServices(callback) {
  GET(
    "/kn-service/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newService(payload, callback) {
  const params = (paramN) =>
    payload?.[paramN]?.reduce((acc, currentVal) => {
      if (currentVal.key) {
        return { ...acc, [currentVal.key]: currentVal.value };
      }

      return acc;
    }, {});
  const configsAndSecrets = payload?.configs_values?.map((it) => it.name);
  const body = JSON.stringify({
    displayName: payload.displayName,
    saName: payload.saName,
    image: payload.image,
    containerPort: payload.containerPort,
    envParams: params("variables"),
    secretParams: params("secrets"),
    envFrom: configsAndSecrets,
    secretsForm: configsAndSecrets,
  });

  POST(
    "/kn-service/",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function deleteService(name, callback) {
  DELETE(
    `/kn-service/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getConfig(callback) {
  GET(
    "/k8s-config/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newConfig(payload, callback) {
  const envParams = payload?.variables?.reduce((acc, currentVal) => {
    return { ...acc, [currentVal.key]: currentVal.value };
  }, {});
  const body = JSON.stringify({
    displayName: payload.displayName,
    params: envParams,
  });

  POST(
    "/k8s-config/",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function updateConfig(payload, callback) {
  const envParams = payload?.variables?.reduce((acc, currentVal) => {
    return { ...acc, [currentVal.key]: currentVal.value };
  }, {});
  const body = JSON.stringify({
    resourceVersion: payload?.metadata?.resourceVersion,
    params: envParams,
  });

  PUT(
    `/k8s-config/${payload?.name}`,
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function deleteConfig(name, callback) {
  DELETE(
    `/k8s-config/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getMetrics(payload, callback) {
  GET(
    `/kn-service/metrics${payload?.name ? `/${payload.name}` : ""}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newImage(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    tag: payload.tag,
    builder: payload.builderName,
    serviceAccountName: payload.serviceAccountName,
    git: {
      repo: payload.gitRepo,
      revision: payload.gitRevision,
    },
  });

  POST(
    "/k8s-image/",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function updateImage(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    tag: payload.tag,
    builder: payload.builderName,
    serviceAccountName: payload.serviceAccountName,
    git: {
      repo: payload.gitRepo,
      revision: payload.gitRevision,
    },
  });

  PUT(
    `/k8s-image/${payload.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteImage(name, callback) {
  DELETE(
    `/k8s-image/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function forceRebuild(payload, callback) {
  POST(
    `/k8s-image/rebuild/${payload.name}`,
    null,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function restartService(payload, callback) {
  PUT(
    `/kn-service/${payload}/restart`,
    null,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getServiceLogs(payload, callback) {
  GET(
    `/k8s-service/logs/${payload.name}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getServiceInstanceLogs(payload, callback) {
  GET(
    `/k8s-service/${payload.name}/instance-logs`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getServiceAccounts(callback) {
  GET(
    "/k8s-service-account/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newServiceAccount(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    secrets: payload.gitSecrets?.map((it) => it.name),
    imagePullSecrets: payload.registrySecrets?.map((it) => it.name),
  });

  POST(
    "/k8s-service-account/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateServiceAccount(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    secrets: payload.gitSecrets?.map((it) => it.name),
    imagePullSecrets: payload.registrySecrets?.map((it) => it.name),
  });

  PUT(
    `/k8s-service-account/${payload.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteServiceAccount(name, callback) {
  DELETE(
    `/k8s-service-account/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getManageDb(callback) {
  GET(
    "/db/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newManageDb(payload, callback) {
  const body = JSON.stringify({
    source: payload.db_source,
    type: payload.db_type,
    name: payload.db_name,
    password: payload.db_pwd,
  });

  POST(
    "/db/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteManageDb(name, callback) {
  DELETE(
    `/db/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getGitSecrets(callback) {
  GET(
    "/k8s-secrets/git",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newGitSecrets(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    username: payload.username,
    password: payload.password,
  });

  POST(
    "/k8s-secrets/git",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateGitSecrets(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    username: payload.username,
    password: payload.password,
  });

  PUT(
    `/k8s-secrets/git/${payload?.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteSecrets(name, callback) {
  DELETE(
    `/k8s-secrets/${name}/`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getOpaqueSecrets(callback) {
  GET(
    "/k8s-secrets/opaque",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newOpaqueSecrets(payload, callback) {
  const envParams = payload?.variables?.reduce((acc, currentVal) => {
    return { ...acc, [currentVal.key]: currentVal.value };
  }, {});
  const body = JSON.stringify({
    displayName: payload.displayName,
    secrets: envParams,
  });

  POST(
    "/k8s-secrets/opaque",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function getRegistrySecrets(callback) {
  GET(
    "/k8s-secrets/registry",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newRegistrySecrets(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    server: payload.server,
    username: payload.username,
    password: payload.password,
  });

  POST(
    "/k8s-secrets/registry",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function updateRegistrySecrets(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    server: payload.server,
    username: payload.username,
    password: payload.password,
  });

  PUT(
    `/k8s-secrets/registry/${payload.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getClusterStores(callback) {
  GET(
    "/k8s-cluster-store/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newClusterStores(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    images: payload.clusterStoreImages,
  });

  POST(
    "/k8s-cluster-store/",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateClusterStores(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    images: payload.clusterStoreImages,
  });

  PUT(
    `/k8s-cluster-store/${payload?.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteClusterStore(name, callback) {
  DELETE(
    `/k8s-cluster-store/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getClusterStacks(callback) {
  GET(
    "/k8s-cluster-stack/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function newClusterStacks(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    id: payload.imageID,
    buildImage: payload.imageBuild,
    runImage: payload.imageRun,
  });

  POST(
    "/k8s-cluster-stack/",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function updateClusterStacks(payload, callback) {
  const body = JSON.stringify({
    resourceVersion: payload.resourceVersion,
    id: payload.imageID,
    buildImage: payload.imageBuild,
    runImage: payload.imageRun,
  });

  PUT(
    `/k8s-cluster-stack/${payload.name}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteClusterStack(name, callback) {
  DELETE(
    `/k8s-cluster-stack/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function setBuild(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    tag: payload.tag,
    git: {
      repo: payload.git_repo,
      revision: payload.git_revision,
      secrets: {
        ...(payload.git_secrets === GIT_NEW_CREDENTIALS
          ? {
              username: payload.git_cred_username,
              password: payload.git_cred_password,
            }
          : { name: payload?.git_cred_name }),
      },
    },
    imagePullSecrets: {
      server: payload.registry_cred_server,
      ...(payload.registry_secrets === REGISTRY_NEW_CREDENTIALS
        ? {
            username: payload.registry_cred_username,
            password: payload.registry_cred_password,
          }
        : { name: payload?.registry_cred_name }),
    },
    clusterStack: {
      id: payload.clusterImageID,
      buildImage: payload.clusterBuildImage,
      runImage: payload.clusterRunImage,
    },
    clusterStore: {
      images: payload.clusterStoreImages,
    },
    buildpackIds: payload.buildpackIds,
  });

  POST(
    "/k8s/wizard",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function setWizard1(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    git: {
      repo: payload.git_repo,
      revision: payload.git_revision,
      accessType: "PUBLIC",
    },
  });

  POST(
    "/k8s/wizard-v1",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function setWizard2(payload, callback) {
  const body = JSON.stringify({
    displayName: payload.displayName,
    git: {
      repo: payload.git_repo,
      revision: payload.git_revision,
      accessType: payload.git_accessType,
      ...(payload.git_accessType === PRIVATE
        ? {
            secrets: {
              ...(payload?.git_secrets === GIT_NEW_CREDENTIALS
                ? {
                    username: payload.git_cred_username,
                    password: payload.git_cred_password,
                  }
                : {
                    name: payload?.git_cred_name,
                    displayName: payload?.git_cred_name,
                  }),
            },
          }
        : {}),
    },
  });

  POST(
    "/k8s/wizard-v2",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function setWizard3(payload, callback) {
  const params = (paramN) =>
    payload?.[paramN]?.reduce((acc, currentVal) => {
      if (currentVal.key) {
        return { ...acc, [currentVal.key]: currentVal.value };
      }

      return acc;
    }, {});

  const configsAndSecrets = payload?.configs_values?.map((it) => it.name);
  const body = JSON.stringify({
    displayName: payload.displayName,
    git: {
      repo: payload.git_repo,
      revision: payload.git_revision,
      accessType: payload.git_accessType,
      ...(payload.git_accessType === PRIVATE
        ? {
            secrets: {
              ...(payload?.git_secrets === GIT_NEW_CREDENTIALS
                ? {
                    username: payload.git_cred_username,
                    password: payload.git_cred_password,
                  }
                : {
                    name: payload?.git_cred_name,
                    displayName: payload?.git_cred_name,
                  }),
            },
          }
        : {}),
    },
    imagePullSecrets: {
      registryType: payload.registry_accessType,
      server: payload.registry_cred_server,
      ...(payload.registry_accessType === PRIVATE_REGISTRY
        ? {
            ...(payload?.registry_secrets === REGISTRY_NEW_CREDENTIALS
              ? {
                  displayName: payload.registry_name,
                  username: payload.registry_cred_username,
                  password: payload.registry_cred_password,
                }
              : {
                  name: payload?.registry_cred_name,
                  displayName: payload?.registry_cred_name,
                }),
          }
        : {}),
    },
    deployAutomatically: payload.deployAutomatically,
    service: {
      name: payload?.service_name,
      displayName: payload?.service_name,
      containerPort: payload?.container_port,
      envParams: params("variables"),
      secretParams: params("secrets"),
      envFrom: configsAndSecrets,
      secretFrom: configsAndSecrets,
      scaleTo0: payload?.scaleTo0,
      dailyScaling: {
        up: payload?.up_time,
        down: payload?.down_time,
        timezone: payload?.timezone,
      },
    },
    deployNewDb: payload?.db_deploy,
    ...(payload?.db_deploy
      ? {
          db: {
            source: payload?.db_source,
            type: payload?.db_type,
            name: payload?.db_name,
            password: payload?.db_pwd,
          },
        }
      : {}),
  });

  POST(
    "/k8s/wizard-v3",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getConfigurationData2Export(payload, callback) {
  GET(
    `/k8s/app/${payload?.uid}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function updateWizard3(payload, callback) {
  const params = (paramN) =>
    payload?.[paramN]?.reduce((acc, currentVal) => {
      if (currentVal.key) {
        return { ...acc, [currentVal.key]: currentVal.value };
      }

      return acc;
    }, {});

  const configsAndSecrets = payload?.configs_values?.map((it) => it.name);
  const body = JSON.stringify({
    displayName: payload.displayName,
    git: {
      repo: payload.git_repo,
      revision: payload.git_revision,
      accessType: payload.git_accessType,
      ...(payload.git_accessType === PRIVATE
        ? {
            secrets: {
              ...(payload?.git_secrets === GIT_NEW_CREDENTIALS
                ? {
                    username: payload.git_cred_username,
                    password: payload.git_cred_password,
                  }
                : {
                    name: payload?.git_cred_name,
                    displayName: payload?.git_cred_name,
                  }),
            },
          }
        : {}),
    },
    imagePullSecrets: {
      registryType: payload.registry_accessType,
      server: payload.registry_cred_server,
      ...(payload.registry_accessType === PRIVATE_REGISTRY
        ? {
            ...(payload?.registry_secrets === REGISTRY_NEW_CREDENTIALS
              ? {
                  displayName: payload.registry_name,
                  username: payload.registry_cred_username,
                  password: payload.registry_cred_password,
                }
              : {
                  name: payload?.registry_cred_name,
                  displayName: payload?.registry_cred_name,
                }),
          }
        : {}),
    },
    deployAutomatically: payload.deployAutomatically,
    service: {
      name: payload?.service_name,
      displayName: payload?.service_name,
      containerPort: payload?.container_port,
      envParams: params("variables"),
      secretParams: params("secrets"),
      envFrom: configsAndSecrets,
      secretFrom: configsAndSecrets,
      scaleTo0: payload?.scaleTo0,
      dailyScaling: {
        up: payload?.up_time,
        down: payload?.down_time,
        timezone: payload?.timezone,
      },
    },
    deployNewDb: payload?.db_deploy,
    ...(payload?.db_deploy
      ? {
          db: {
            source: payload?.db_source,
            type: payload?.db_type,
            name: payload?.db_name,
            password: payload?.db_pwd,
          },
        }
      : {}),
  });

  PUT(
    `/k8s/wizard-v3/${payload?.displayName}`,
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function getBuilds(payload, callback) {
  GET(
    "/k8s/builds",
    payload?.imageName ? { imageName: payload?.imageName } : "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function fetchLogs(payload, callback) {
  const body = JSON.stringify({
    imageName: payload.imageName,
    buildNumber: payload.buildNumber,
  });

  POST(
    "/k8s/logs",
    body,
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteBuild(name, callback) {
  DELETE(
    `/k8s/build/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getConfigsAndSecrets(callback) {
  GET(
    "/c2s-configs-and-secrets/",
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function neweConfigsAndSecrets(payload, callback) {
  const params = (paramN) =>
    payload?.[paramN]?.reduce((acc, currentVal) => {
      if (currentVal.key) {
        return { ...acc, [currentVal.key]: currentVal.value };
      }

      return acc;
    }, {});
  const body = JSON.stringify({
    displayName: payload.displayName,
    configs: params("variables"),
    secrets: params("secrets"),
  });

  POST(
    "/c2s-configs-and-secrets/",
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function updateConfigsAndSecrets(payload, callback) {
  const params = (paramN) =>
    payload?.[paramN]?.reduce((acc, currentVal) => {
      if (currentVal.key) {
        return { ...acc, [currentVal.key]: currentVal.value };
      }

      return acc;
    }, {});
  const body = JSON.stringify({
    resourceVersion: payload?.metadata?.resourceVersion,
    configs: params("variables"),
    secrets: params("secrets"),
  });

  PUT(
    `/c2s-configs-and-secrets/${payload?.name}`,
    body,
    (result, status) => {
      callback(status === 200 && result, status !== 200 && result?.message);
    },
    K8S_API_URL
  );
}

export function deleteConfigsAndSecrets(name, callback) {
  DELETE(
    `/c2s-configs-and-secrets/${name}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getSecret(payload, callback) {
  GET(
    `/c2s-secret/${payload.name}/${payload.key}`,
    "",
    (result, status) => {
      callback(
        status === 200 && result,
        status !== 200 && (result?.message ?? t.errorMsg)
      );
    },
    K8S_API_URL
  );
}

export function deleteApplication(uid, callback) {
  DELETE(
    `/k8s/app/${uid}`,
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}

export function getAppByImageUid(payload, callback) {
  GET(
    `/k8s/app/${payload}`,
    "",
    (result, status) => {
      callback &&
        callback(
          status === 200 && result,
          status !== 200 && (result?.message ?? t.errorMsg)
        );
    },
    K8S_API_URL
  );
}
