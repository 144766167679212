import React, { useEffect, useState } from "react";

import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import {
  Step,
  Stepper,
  StepButton,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import t from "../helpers/en";
import {
  getGitSecrets,
  // setBuild,
  setWizard3,
  getRegistrySecrets,
  getSecret,
  getConfigsAndSecrets,
  updateWizard3,
} from "../helpers/utils";
import {
  GIT_NEW_CREDENTIALS,
  REGISTRY_NEW_CREDENTIALS,
  PRIVATE,
  PRIVATE_REGISTRY,
  JSON_ADD_VARIABLES,
} from "../helpers/constants";
import {
  changeTxt,
  selectOption,
  // string2Array,
  configsAndSecretsShowPwd,
  setConfigsAndSecrets,
  changeSubTxt,
  setJsonVariable2Table,
} from "../helpers/selectors";

import Select from "../components/Inputs/Select";
import SetConfigsAndSecrets from "../components/SetConfigsAndSecrets";

const PUBLIC = "PUBLIC";
const PLATFORM_REGISTRY = "PLATFORM_REGISTRY";
const ACCESS_TYPE = [
  { id: PUBLIC, name: t.public },
  { id: PRIVATE, name: t.private },
];
const REGISTRY_ACCESS_TYPE = [
  { id: PLATFORM_REGISTRY, name: t.platformHostedRegistry },
  { id: PRIVATE_REGISTRY, name: t.private },
];
const timezones = [
  { id: "PST", label: "PST" },
  { id: "CST", label: "CST" },
  { id: "EST", label: "EST" },
];

const styleSteps = {
  "& .MuiStepConnector-root, .MuiStepLabel-iconContainer": {
    display: "none",
  },
  justifyContent: "center",
  marginBottom: "1.5rem",
};
const styleStepBtn = {
  "& .MuiButtonBase-root": {
    m: 0,
    p: 0,
  },
  "&.MuiStep-root": {
    padding: "0 !important",
  },
};

const sectionLabel = {
  display: "flex",
  width: "100%",
  fontSize: "0.696429rem",
  lineHeight: 1.66,
  fontWeight: 400,
  opacity: 0.7,
  background: "#f0f8ff",
  padding: "2px",
};
const styleQuestionLabel = {
  fontSize: "0.696429rem",
  lineHeight: 1.66,
  fontWeight: 400,
  marginLeft: "25px",
};
const styleQuestionAnswer = {
  fontSize: "0.8125rem",
  lineHeight: 1.43,
  fontWeight: 400,
};

const steps = [
  t.basicInfo,
  t.sourceInfo,
  t.dockerImage,
  t.applicationInfo,
  t.reviewPlusCreate,
];
const MAIN_BRANCH = "main";
const CUSTOM_BRANCH = "custom";
const MASTER_BRANCH = "master";
const INITIAL_STATE_FORM = {
  git_accessType: PUBLIC,
  registry_accessType: PLATFORM_REGISTRY,
  name: "system-default",
  deployAutomatically: true,
  scaleTo0: true,
  db_deploy: false,
  variables: [{ key: null, value: null }],
  secrets: [{ key: null, value: null, isPwdVisible: false }],
  configs_values: [],
  tabVal: 0,
  git_revision_selector: MAIN_BRANCH,
  git_revision: "main",
};
const GIT_BRANCHES = [
  { id: MAIN_BRANCH, label: "Main" },
  { id: MASTER_BRANCH, label: "Master" },
  { id: CUSTOM_BRANCH, label: "Custom" },
];

const BasicsContent = ({ form, onChangeTxt }) => (
  <>
    <TextField
      id={`outlined-basic-name`}
      label={t.application + " " + t.name}
      name="displayName"
      value={form?.displayName || ""}
      onChange={onChangeTxt}
      // InputProps={{
      //   readOnly: form?.isEditForm,
      // }}
      size="small"
    />
  </>
);
const GitComponent = ({
  form,
  onChangeTxt,
  onSelectOption,
  gitCredentials,
  showCredentials = true,
}) => (
  <>
    <TextField
      id="outlined-basic-git_repo"
      label={t.git + " " + t.repository}
      name="git_repo"
      value={form?.git_repo || ""}
      onChange={onChangeTxt}
      // InputProps={{
      //   readOnly: form?.isEditForm,
      // }}
      size="small"
    />
    <Select
      options={GIT_BRANCHES}
      onChange={(evt, val) => onSelectOption(evt, val, "git_revision_selector")}
      label={t.git + " " + t.revision}
      item="label"
      value={
        GIT_BRANCHES?.find((it) => it.id === form?.git_revision_selector) || ""
      }
    />
    {form?.git_revision_selector === CUSTOM_BRANCH && (
      <TextField
        id="outlined-basic-git_revison"
        label={`Type in the input custom ${t.git + " " + t.revision}`}
        name="git_revision"
        value={form?.git_revision || ""}
        onChange={onChangeTxt}
        size="small"
      />
    )}
    {showCredentials && (
      <Select
        options={ACCESS_TYPE}
        onChange={(evt, val) => onSelectOption(evt, val, "git_accessType")}
        label={t.git + " " + t.accessType}
        item="name"
        value={ACCESS_TYPE?.find((it) => it.id === form?.git_accessType) || ""}
      />
    )}

    {form?.git_accessType === PRIVATE && (
      <Select
        options={gitCredentials}
        onChange={(evt, val) => onSelectOption(evt, val, "git_secrets")}
        label={t.privateRepository}
        item="name"
        value={gitCredentials?.find((it) => it.id === form?.git_secrets) || ""}
        specificDetails={{
          styles: {
            elemLi: (el) =>
              (el?.default || el?.id === GIT_NEW_CREDENTIALS) && {
                borderTop: "1px solid rgba(0,0,0,0.3)",
              },
          },
        }}
      />
    )}
    {form?.git_secrets === GIT_NEW_CREDENTIALS &&
      form?.git_accessType === PRIVATE && (
        <Box sx={{ display: "grid", gridRowGap: "1rem", ml: "1.5rem" }}>
          <TextField
            id="outlined-basic-git_secrets_username"
            label={t.username}
            name="git_cred_username"
            value={form?.git_cred_username || ""}
            onChange={onChangeTxt}
            size="small"
          />
          <TextField
            id="outlined-basic-git_secrets_password"
            label={t.password}
            name="git_cred_password"
            value={form?.git_cred_password || ""}
            onChange={onChangeTxt}
            size="small"
          />
        </Box>
      )}
  </>
);
const RegistryComponent = ({
  registryCredentials,
  onSelectOption,
  form,
  onChangeTxt,
}) => (
  <>
    <Select
      options={REGISTRY_ACCESS_TYPE}
      onChange={(evt, val) => onSelectOption(evt, val, "registry_accessType")}
      label={`${t.registry} ${t.accessType}`}
      item="name"
      value={
        REGISTRY_ACCESS_TYPE?.find(
          (it) => it.id === form?.registry_accessType
        ) || ""
      }
    />
    {form?.registry_accessType === PRIVATE_REGISTRY && (
      <Select
        options={registryCredentials}
        onChange={(evt, val) => onSelectOption(evt, val, "registry_secrets")}
        label={t.registry}
        item="name"
        value={
          registryCredentials?.find((it) => it.id === form?.registry_secrets) ||
          ""
        }
        specificDetails={{
          styles: {
            elemLi: (el) =>
              (el?.default || el?.id === REGISTRY_NEW_CREDENTIALS) && {
                borderTop: "1px solid rgba(0,0,0,.3)",
              },
          },
        }}
      />
    )}
    {form?.registry_accessType === PRIVATE_REGISTRY &&
      form?.registry_secrets === REGISTRY_NEW_CREDENTIALS && (
        <Box
          sx={{
            display: "grid",
            gridRowGap: "1rem",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "0.4rem",
            p: "1rem",
          }}
        >
          <TextField
            id={`outlined-registry_name`}
            label={`${t.registry} ${t.name}`}
            name="registry_name"
            value={form?.registry_name || ""}
            onChange={onChangeTxt}
            size="small"
          />
          <TextField
            id="outlined-basic-registry_secrets_server"
            label={t.server}
            name="registry_cred_server"
            value={form?.registry_cred_server || ""}
            onChange={onChangeTxt}
            size="small"
          />
          <TextField
            id="outlined-basic-registry_secrets_username"
            label={t.username}
            name="registry_cred_username"
            value={form?.registry_cred_username || ""}
            onChange={onChangeTxt}
            size="small"
          />
          <TextField
            id="outlined-basic-registry_secrets_password"
            label={t.password}
            name="registry_cred_password"
            value={form?.registry_cred_password || ""}
            onChange={onChangeTxt}
            size="small"
          />
        </Box>
      )}
  </>
);
// const ClusterStackContent = ({ form, onChangeTxt }) => (
//   <>
//     <TextField
//       id={`outlined-basic-cluster_image_id`}
//       label={t.id}
//       name="clusterImageID"
//       value={form?.clusterImageID || ""}
//       onChange={onChangeTxt}
//     />
//     <TextField
//       id={`outlined-basic-cluster_build_image`}
//       label={t.buildImage}
//       name="clusterBuildImage"
//       value={form?.clusterBuildImage || ""}
//       onChange={onChangeTxt}
//     />
//     <TextField
//       id={`outlined-basic-cluster_run_image`}
//       label={t.runImage}
//       name="clusterRunImage"
//       value={form?.clusterRunImage || ""}
//       onChange={onChangeTxt}
//     />
//   </>
// );
// const ClusterStoreContent = ({ form, onChangeTxt }) => (
//   <TextField
//     id="outlined-basic-cluster_store"
//     label={t.clusterStore}
//     name="clusterStore"
//     value={form?.clusterStore || ""}
//     onChange={onChangeTxt}
//     rows={2}
//     multiline={true}
//     helperText={t.separateEachElementInfoMsg}
//   />
// );
// const BuildpackContent = ({ form, onChangeTxt }) => (
//   <TextField
//     id="outlined-basic-buildpack"
//     label={t.buildpack}
//     name="buildpack"
//     value={form?.buildpack || ""}
//     onChange={onChangeTxt}
//     rows={2}
//     multiline={true}
//     helperText={t.separateEachElementInfoMsg}
//   />
// );

const ServiceComponent = ({
  form,
  onChangeTxt,
  onCheckboxChange,
  configs,
  onSelectMultipleOptions,
  onChangeTab,
  onChangeSubTxt,
  onSetConfigsAndSecrets,
  onOpenSecond,
  onShowPwd,
  onChangeTxtJson,
  onSetJsonVariable2Table,
  onHideModalSecond,
  onSelectOption,
}) => (
  <>
    <FormControlLabel
      label={t.deployAutomatically}
      control={
        <Checkbox
          checked={form.deployAutomatically}
          onChange={onCheckboxChange}
          name="deployAutomatically"
        />
      }
    />
    {form.deployAutomatically && (
      <TextField
        id="outlined-basic-container_port"
        label={t.containerPort}
        name="container_port"
        value={form?.container_port || ""}
        onChange={onChangeTxt}
        size="small"
      />
    )}
    <Select // CONFIGS & SECRETS TOGETHER
      multiple
      options={configs}
      onChange={(evt, val) =>
        onSelectMultipleOptions(evt, val, "configs_values") || []
      }
      label={t.configsAndSecrets}
      item="displayName"
      value={form?.configs_values}
    />

    <SetConfigsAndSecrets
      {...{
        form,
        onChangeTab,
        onChangeSubTxt,
        onSetConfigsAndSecrets,
        onOpenSecond,
        onShowPwd,
        onChangeTxtJson,
        onSetJsonVariable2Table,
        onHideModalSecond,
      }}
    />
    <FormControlLabel
      label={t.scaleTo0}
      control={
        <Checkbox
          checked={form.scaleTo0}
          onChange={onCheckboxChange}
          name="scaleTo0"
        />
      }
    />
    {form.scaleTo0 && (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "1rem",
        }}
      >
        <TextField
          label="Up Time"
          type="time"
          name="up_time"
          value={form?.up_time || ""}
          onChange={onChangeTxt}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          size="small"
        />
        <TextField
          label="Down Time"
          type="time"
          name="down_time"
          value={form?.down_time || ""}
          onChange={onChangeTxt}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          size="small"
        />
        <Select
          options={timezones}
          onChange={(evt, val) => onSelectOption(evt, val, "timezone")}
          label={"Timezone"}
          item="label"
          value={timezones?.find((it) => it.id === form?.timezone) || ""}
        />
      </div>
    )}
  </>
);

const ConfigureBuild = (props) => {
  const { showError, triggerUpdateTable, handleClose, data, currentUser } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({
    ...INITIAL_STATE_FORM,
  });
  const [configs, setConfigs] = useState([]);
  const [gitCredentials, setGitCredentials] = useState([]);
  const [registryCredentials, setRegistryCredentials] = useState({});

  const { currentGroup } = useSharedProps();

  const fetchGitCregential = () => {
    return new Promise((resolve, reject) => {
      getGitSecrets((result, err) => {
        if (Array.isArray(result)) {
          const updatedResult = [
            ...result.map((it) => ({
              git_cred_username: it?.data?.username,
              git_cred_password: it?.data?.password,
              id: it?.metadata?.name,
              name: it?.metadata?.displayName,
              default: Boolean(it?.metadata?.labels?.default),
            })),
            { id: GIT_NEW_CREDENTIALS, name: t.newCredentials },
          ];

          resolve(updatedResult);
        } else {
          reject(err);
        }
      });
    });
  };
  const fetchRegistryCredential = () => {
    return new Promise((resolve, reject) => {
      getRegistrySecrets((result, err) => {
        if (Array.isArray(result)) {
          const updatedResult = [
            ...result.map((it) => ({
              registry_cred_server: it?.data?.server,
              registry_cred_username: it?.data?.username,
              registry_cred_password: it?.data?.password,
              id: it?.metadata?.name,
              name: it?.metadata?.displayName,
              default: Boolean(it?.metadata?.labels?.default),
            })),
            { id: REGISTRY_NEW_CREDENTIALS, name: t.newRegistry },
          ];

          resolve(updatedResult);
        } else {
          reject(err);
        }
      });
    });
  };
  const fetchRegistrySecrets = () => {
    return new Promise((resolve, reject) => {
      getConfigsAndSecrets((result, err) => {
        if (Array.isArray(result)) {
          const updatedResult = result.map((it) => ({
            displayName: it?.displayName,
            name: it?.name,
          }));

          resolve(updatedResult);
        } else {
          reject(err);
        }
      });
    });
  };

  useEffect(() => {
    Promise.all([
      fetchGitCregential(),
      fetchRegistryCredential(),
      fetchRegistrySecrets(),
    ])
      .then(([gitResult, registryResult, configResult]) => {
        if (Array.isArray(gitResult)) {
          setGitCredentials(gitResult);
        }

        if (Array.isArray(registryResult)) {
          setRegistryCredentials(registryResult);
        }

        if (Array.isArray(configResult)) {
          setConfigs(configResult);
        }

        if (data?.isEditForm) {
          setForm({
            displayName: data?.displayName,
            git_repo: data.git?.repo,
            git_revision_selector:
              data.git?.revision === MAIN_BRANCH ||
              data.git?.revision === MASTER_BRANCH
                ? data.git?.revision
                : CUSTOM_BRANCH,
            git_revision: data.git?.revision,
            git_accessType: data.git?.accessType,
            git_secrets: data.git?.secrets?.name,
            git_cred_name: data.git?.secrets?.name,
            registry_cred_name: data.imagePullSecrets?.name,
            registry_accessType: data.imagePullSecrets?.registryType,
            registry_secrets: data.imagePullSecrets?.name,
            registry_cred_server: data.imagePullSecrets?.server,
            deployAutomatically: data.deployAutomatically,
            container_port: data.service?.containerPort,
            variables: Object.entries(data.service?.envParams || {})?.map(
              ([key, val]) => ({ key, value: val })
            ),
            secrets: Object.entries(data.service?.secretParams || {})?.map(
              ([key, val]) => ({ key, value: val })
            ),
            configs_values: configResult.filter((it) =>
              (data.service?.envFrom || []).some((el) => el === it.name)
            ),
            scaleTo0: data.service?.scaleTo0,
            db_deploy: false,
            tabVal: 0,
            up_time: formatTime(
              data.service?.dailyScaling?.up[0] || 0,
              data.service?.dailyScaling?.up[1] || 0
            ),
            down_time: formatTime(
              data.service?.dailyScaling?.down[0] || 0,
              data.service?.dailyScaling?.down[1] || 0
            ),
            timezone: data.service?.dailyScaling?.timezone,
            isEditForm: data.isEditForm,
          });
        }
      })
      .catch((err) => {
        showError(err);
      });
  }, [data, showError, currentGroup]);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const go2LastStep = () => {
    const lastStep = steps.length - 1;

    setActiveStep(lastStep);
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value?.id ?? null, param, form);

    if (param === "git_secrets") {
      result = {
        ...result,
        git_cred_username: value?.git_cred_username,
        git_cred_password: value?.git_cred_password,
        git_cred_name: value?.id,
      };
    } else if (param === "registry_secrets") {
      result = {
        ...result,
        registry_cred_username: value?.registry_cred_username,
        registry_cred_password: value?.registry_cred_password,
        registry_cred_server: value?.registry_cred_server,
        registry_cred_name: value?.id,
      };
    } else if (param === "git_revision_selector") {
      result = {
        ...result,
        git_revision: value?.id === CUSTOM_BRANCH ? "" : value?.id,
      };
    }

    setForm(result);
  };

  const onCheckboxChange = (evt) => {
    const { name, checked } = evt.target;

    setForm({ ...form, [name]: checked });
  };

  const onSelectMultipleOptions = (event, values, param) => {
    let cpy = [...(form?.[param] ?? [])];
    cpy = values;
    setForm({ ...form, [param]: cpy });
  };

  const onChangeTab = (_, val) => {
    setForm((prev) => ({ ...prev, tabVal: val }));
  };

  const onShowPwd = async (e, elem) => {
    const result = await configsAndSecretsShowPwd(e, {
      ...elem,
      showError,
      getSecret,
    });

    setForm(result);
  };

  const onSetConfigsAndSecrets = (evt, elem) => {
    const result = setConfigsAndSecrets(evt, elem);

    setForm(result);
  };

  const onChangeTxtJson = (e) => {
    const value = e.target.value;

    setForm((prev) => ({
      ...prev,
      ...{ secondModal: { ...prev.secondModal, value } },
    }));
  };

  const onSetJsonVariable2Table = () => {
    const result = setJsonVariable2Table(form);

    setForm(result);
  };

  const onChangeSubTxt = (evt, val, valIdx, param) => {
    const result = changeSubTxt(evt, form, valIdx, param);

    setForm(result);
  };

  const onOpenSecond = (evt, param) => {
    const { id } = evt.target;
    let cpy = { ...form };
    const envParams = form?.[param]?.reduce((acc, currentVal) => {
      return {
        ...acc,
        ...(currentVal.key ? { [currentVal.key]: currentVal.value } : {}),
      };
    }, {});

    cpy = {
      ...cpy,
      secondModal: {
        open: true,
        ...(id === JSON_ADD_VARIABLES
          ? { value: JSON.stringify(envParams, null, 2) }
          : {}),
      },
    };

    setForm(cpy);
  };

  const onHideModalSecond = () => {
    let cpy = { ...form };

    cpy.secondModal = null;

    setForm(cpy);
  };

  const formatTime = (hour, min) => {
    return `${hour.toString().padStart(2, "0")}:${min
      .toString()
      .padStart(2, "0")}`;
  };

  // const onSave = () => {
  //   const clusterStoreImages = string2Array(form?.clusterStore);
  //   const buildpackIds = string2Array(form?.buildpack);

  //   setBuild({ ...form, clusterStoreImages, buildpackIds }, (result, err) => {
  //     if (!err) {
  //       setForm({});
  //       setActiveStep(0);
  //     } else {
  //       showError(err);
  //     }
  //   });
  // };

  const onSave = () => {
    const triggerUpdate = (result, err, cb) => {
      if (!err) {
        setForm({ ...INITIAL_STATE_FORM });
        cb && cb();
      } else {
        showError(err);
      }
    };

    if (data?.isEditForm && !data?.isSetJson) {
      updateWizard3(
        { currentName: data?.metadata?.name, ...form },
        (result, errorMsg) => {
          triggerUpdate(result, errorMsg, triggerUpdateTable);
        }
      );
    } else {
      setWizard3(form, (result, errorMsg) => {
        triggerUpdate(result, errorMsg, triggerUpdateTable);
      });
    }
  };

  let content;

  if (activeStep === 0) {
    content = <BasicsContent {...{ form, onChangeTxt, currentUser, onSelectOption }} />;
  } else if (activeStep === 1) {
    content = (
      <GitComponent
        {...{
          registryCredentials,
          form,
          onChangeTxt,
          onSelectOption,
          gitCredentials,
        }}
      />
    );
  } else if (activeStep === 2) {
    content = (
      <RegistryComponent
        {...{ registryCredentials, onSelectOption, form, onChangeTxt }}
      />
    );
  } else if (activeStep === 3) {
    content = (
      <>
        <ServiceComponent
          {...{
            form,
            onChangeTxt,
            onCheckboxChange,
            configs,
            onSelectMultipleOptions,
            onChangeTab,
            onChangeSubTxt,
            onSetConfigsAndSecrets,
            onOpenSecond,
            onShowPwd,
            onChangeTxtJson,
            onSetJsonVariable2Table,
            onHideModalSecond,
            onSelectOption,
          }}
        />
      </>
    );
  } else {
    content = (
      <>
        <Box>
          <span style={{ ...sectionLabel }}>{t.basicInfo}</span>
          <span style={{ ...styleQuestionLabel }}>
            {t.application + " " + t.name} :
          </span>{" "}
          <span style={{ ...styleQuestionAnswer }}>{form?.displayName}</span>
        </Box>

        <Box>
          <span style={{ ...sectionLabel }}>{t.sourceInfo}</span>
          <span style={{ ...styleQuestionLabel }}>
            {t.git + " " + t.repository} :
          </span>{" "}
          <span style={{ ...styleQuestionAnswer }}>{form?.git_repo}</span>
          <br />
          <span style={{ ...styleQuestionLabel }}>
            {t.git + " " + t.revision} :
          </span>{" "}
          <span style={{ ...styleQuestionAnswer }}>{form?.git_revision}</span>
          <br />
          <span style={{ ...styleQuestionLabel }}>
            {t.git + " " + t.accessType} :
          </span>{" "}
          <span style={{ ...styleQuestionAnswer }}>{form?.git_accessType}</span>
          <br />
          {form?.git_accessType !== PUBLIC && (
            <>
              <span style={{ ...styleQuestionLabel }}>
                {t.privateRepository} :
              </span>{" "}
              <span style={{ ...styleQuestionAnswer }}>
                {form?.git_secrets}
              </span>
              <br />
              {form?.git_secrets === GIT_NEW_CREDENTIALS && (
                <>
                  <span style={{ ...styleQuestionLabel }}>{t.username} :</span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.git_cred_username || ""}
                  </span>
                  <br />
                  <span style={{ ...styleQuestionLabel }}>
                    {t.password} :
                  </span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.git_cred_password || ""}
                  </span>
                  <br />
                </>
              )}
            </>
          )}
        </Box>

        <Box>
          <span style={{ ...sectionLabel }}>{t.dockerImage}</span>
          <span style={{ ...styleQuestionLabel }}>
            {`${t.registry} ${t.accessType}`} :
          </span>
          <span style={{ ...styleQuestionAnswer }}>
            {" "}
            {form?.registry_accessType}
          </span>
          <br />
          {form?.registry_accessType !== PLATFORM_REGISTRY && (
            <>
              <span style={{ ...styleQuestionLabel }}> {t.registry} :</span>{" "}
              <span style={{ ...styleQuestionAnswer }}>
                {form?.registry_secrets}
              </span>
              <br />
              {form?.registry_secrets === REGISTRY_NEW_CREDENTIALS && (
                <>
                  <span style={{ ...styleQuestionLabel }}>
                    {t.registry} {t.name} :
                  </span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.registry_name || ""}
                  </span>
                  <br />
                  <span style={{ ...styleQuestionLabel }}>
                    {t.server} :
                  </span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.registry_cred_server || ""}
                  </span>
                  <br />
                  <span style={{ ...styleQuestionLabel }}>
                    {t.username} :
                  </span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.registry_cred_username || ""}
                  </span>
                  <br />
                  <span style={{ ...styleQuestionLabel }}>
                    {t.password} :
                  </span>{" "}
                  <span style={{ ...styleQuestionAnswer }}>
                    {form?.registry_cred_password || ""}
                  </span>
                  <br />
                </>
              )}
            </>
          )}
        </Box>

        <Box>
          <span style={{ ...sectionLabel }}>{t.applicationInfo}</span>
          <span style={{ ...styleQuestionLabel }}>
            {t.deployAutomatically} :{" "}
          </span>
          <span style={{ ...styleQuestionAnswer }}>
            {form.deployAutomatically ? "ON" : "OFF"}
          </span>
          <br />
          {form.deployAutomatically && (
            <>
              <span style={{ ...styleQuestionLabel }}>{t.containerPort} :</span>{" "}
              <span style={{ ...styleQuestionAnswer }}>
                {form?.container_port}
              </span>
              <br />
            </>
          )}
          <span style={{ ...styleQuestionLabel }}>{t.configsAndSecrets} :</span>
          <span style={{ ...styleQuestionAnswer }}>
            {" "}
            {JSON.stringify(form.configs_values)}
          </span>
          <br />
          <span style={{ ...styleQuestionLabel }}>{t.scaleTo0} :</span>
          <span style={{ ...styleQuestionAnswer }}>
            {" "}
            {form.scaleTo0 ? "ON" : "OFF"}
          </span>
        </Box>
      </>
    );
  }

  return (
    <>
      <Stepper nonLinear sx={{ ...styleSteps }}>
        {steps.map((label, index) => (
          <Step sx={{ ...styleStepBtn }} key={label}>
            <StepButton
              sx={{
                ...styleStepBtn,
                borderBottom: (theme) =>
                  activeStep === index
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid rgba(229,231,235,0.6)",
                "&:hover": {
                  opacity: 0.6,
                },
              }}
              onClick={handleStep(index)}
            >
              <span style={{ padding: "1rem" }}>{label}</span>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          minHeight: "calc(100% - 6em)",
        }}
      >
        {content}
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "sticky",
          bottom: 0,
          background: "#fff",
          padding: "1rem 0",
          zIndex: 10,
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 1, textTransform: "none" }}
          onClick={handleClose}
        >
          {t.close}
        </Button>
        {activeStep !== 0 && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1, textTransform: "none", marginLeft: "auto" }}
          >
            {t.previous}
          </Button>
        )}
        {steps.length > activeStep + 1 && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleNext}
            sx={{
              mr: 1,
              textTransform: "none",
              ...(activeStep === 0 ? { marginLeft: "auto" } : {}),
            }}
          >
            {t.next}: {steps[activeStep + 1]}
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={activeStep !== steps.length - 1 ? go2LastStep : onSave}
        >
          {activeStep !== steps.length - 1 ? t.reviewPlusCreate : t.create}
        </Button>
      </Box>
    </>
  );
};

export default withErrorHandling(ConfigureBuild);
