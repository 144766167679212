import React from "react";

import { ClickAwayListener } from "@mui/material";

const PopupWindow = ({ specificDetails }) => {
  return (
    <ClickAwayListener onClickAway={specificDetails?.onClose}>
      <div
        style={{
          position: "absolute",
          top: `${specificDetails?.popupPosition?.top}px`,
          left: `${specificDetails?.popupPosition?.left}px`,
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
          overflow: "auto",
          ...(specificDetails?.style?.div ?? {}),
        }}
      >
        {specificDetails?.actions}
      </div>
    </ClickAwayListener>
  );
};

export default PopupWindow;
