import React, { useState, useEffect } from "react";

import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import { Box, Button, IconButton, MenuItem } from "@mui/material";

import { MoreHoriz } from "@mui/icons-material";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../helpers/en";
import {
  EDIT_ITEM,
  DELETE_ITEM,
  JSON_ITEM,
  NEW_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
} from "../helpers/constants";
import {
  deleteManageDb,
  getManageDb,
  newManageDb,
  getk8sLogs,
} from "../helpers/utils";
import { filterRecords, changeTxt, selectOption } from "../helpers/selectors";
import usePopup from "../helpers/contexts/usePopup";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import DbFields from "../components/DbFileds";
import PopupWindow from "../components/PopupWindow";

import { styleTableMenuOption, styleButton } from "../GeneralStyles";

const AUDIT_LOGS = "AUDIT_LOGS";

const rowsParams = (data) => ({
  header: [
    t.name,
    t.type,
    t.host,
    t.port,
    t.username,
    t.resourceVersion,
    t.createdOn,
  ],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.metadata?.displayName },
    { value: val?.metadata?.labels?.dbType },
    { value: val?.metadata?.["db-host"] },
    { value: val?.metadata?.["db-port"] },
    { value: val?.metadata?.["db-username"] },
    { value: val?.metadata?.resourceVersion },
    { value: val?.metadata?.creationTimestamp },
  ],
});

const ManageDb = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();
  const {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    onMouseLeave,
    openPopupForItem,
  } = usePopup(80);

  const fetchData = (cb, onError) => {
    getManageDb((result, err) => {
      if (!err) {
        result && setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      fetchData(cb, showError);
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result, err) => {
            if (!err) {
              resolve({ ...row, logs: [...(result.content ?? [])] });
            } else {
              showError(err);
            }
          }
        );
      }).then((res) => setForm(res));
    } else {
      modalData = {
        ...row,
        ...(id === EDIT_ITEM
          ? {
              displayName: row?.metadata?.displayName,
              name: row?.metadata?.name,
              resourceVersion: row?.metadata?.resourceVersion,
              username: row?.data?.username,
            }
          : {}),
      };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onDelete = (evt, row) => {
    deleteManageDb(row.metadata.name, (result) => {
      triggerUpdate();
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    handleClose();
  };

  const onSave = (action) => {
    action({ ...form }, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value?.id ?? null, param, form);

    setForm(result);
  };

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case NEW_ITEM:
      title = t.add(t.database);
      content = <DbFields {...{ onSelectOption, onChangeTxt, form }} />;
      onConfirmModalAction = () => onSave(newManageDb);
      saveLabel = t.save;
      break;
    case DELETE_ITEM:
      title = t.delete(t.database);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    case AUDIT_LOGS:
      title = t.events;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Button
        sx={{ mb: 2, ...styleButton }}
        variant="contained"
        onClick={(evt) => onOpen(evt, null)}
        id={NEW_ITEM}
        disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
      >
        {t.addNew}
      </Button>

      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => {
          return item?.metadata?.name === form?.metadata?.name;
        }}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <IconButton
            sx={{ padding: 0 }}
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();

              openPopup(item, e.currentTarget);
            }}
          >
            <MoreHoriz sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        )}
      />

      {openPopupForItem && (
        <div ref={popupRef} onMouseEnter={() => {}} {...{ onMouseLeave }}>
          <PopupWindow
            specificDetails={{
              onClose: closePopup,
              popupPosition: popupPosition,
              style: { div: { width: "140px" } },
              actions: [
                {
                  id: JSON_ITEM,
                  label: t.json,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },
                {
                  id: AUDIT_LOGS,
                  label: t.events,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },
                ...(!openPopupForItem?.metadata?.labels?.default
                  ? [
                      {
                        id: DELETE_ITEM,
                        label: t.destroy,
                        onClick: (evt) => onOpen(evt, openPopupForItem),
                        disabled:
                          currentGroup?.id &&
                          !currentGroup?.isContributor2Group,
                      },
                    ]
                  : []),
              ]?.map((item, idx) => (
                <MenuItem
                  sx={{ ...styleTableMenuOption }}
                  key={idx}
                  id={item.id}
                  onClick={(evt) => {
                    if (item.onClick) {
                      item.onClick(evt);
                      closePopup();
                    }
                  }}
                  disabled={item.disabled}
                >
                  {item.label}
                </MenuItem>
              )),
            }}
          />
        </div>
      )}

      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />
    </Box>
  );
};

export default withErrorHandling(ManageDb);
