import { useState, useRef, useCallback, useEffect } from "react";

//  Custom hook for handling a popover/popup behavior

const usePopup = (offset) => {
  const [openPopupForItem, setOpenPopupForItem] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const popupRef = useRef(null);

  // Opens the popup and sets the position based on button element's position
  const openPopup = useCallback(
    (item, buttonElement) => {
      const buttonRect = buttonElement.getBoundingClientRect();
      const adjustedLeft = buttonRect.left + window.scrollX - offset;
      const adjustedTop = buttonRect.top + window.scrollY + buttonRect.height;

      setPopupPosition({
        top: adjustedTop,
        left: Math.max(adjustedLeft, 10),
      });
      setOpenPopupForItem(item);
    },
    [offset]
  );

  const closePopup = () => {
    // Close the popup
    setOpenPopupForItem(null);
  };

  const onClickOutside = useCallback((event) => {
    //close popup on click outside the popup
    if (
      event.target &&
      buttonRef.current &&
      !buttonRef.current?.contains(event.target) &&
      popupRef.current &&
      !popupRef.current?.contains(event.target)
    ) {
      closePopup();
    }
  }, []);

  useEffect(() => {
    // listen for clicks outside the button and popup when the popup is open
    if (openPopupForItem) {
      document.addEventListener("click", onClickOutside);

      return () => {
        document.removeEventListener("click", onClickOutside);
      };
    }
  }, [openPopupForItem, onClickOutside]);

  return {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    openPopupForItem,
  };
};

export default usePopup;
