import React, { useState, useEffect, Fragment } from "react";

import withErrorHandling from "../HOC/ErrorHandling";

import { Visibility, VisibilityOff, MoreHoriz } from "@mui/icons-material";

import {
  Box,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { USER_SOURCE, USERS_ROLES, USER_STATUS } from "../helpers/constants";
import { getUsers, createUser, updateUser, deleteUser } from "../helpers/utils";
import { filterRecords, changeTxt, selectOption } from "../helpers/selectors";
import t from "../helpers/en";
import usePopup from "../helpers/contexts/usePopup";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import Loading from "../components/Loading";
import Select from "../components/Inputs/Select";
import PopupWindow from "../components/PopupWindow";

import { styleTableMenuOption, styleButton } from "../GeneralStyles";

const EDIT_USER = "EDIT_USER";
const DELETE_USER = "DELETE_USER";
const ADD_USER = "ADD_USER";

const Users = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [pressedBtn, setPressedBtn] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    onMouseLeave,
    openPopupForItem,
  } = usePopup(80);

  const onOpen = (evt, row) => {
    const { id } = evt.target;

    const modalData = {
      ...row,
      ...(id === EDIT_USER ? { group: row?.group?.id } : {}),
    };

    setForm(modalData);
    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const fetchData = (cb, onError) => {
    getUsers((result, err) => {
      if (!err) {
        setData({ all: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.givenName,
      item.familyName,
      item.email,
      item.username,
      item.role,
    ]);

    setData(res);
  };

  useEffect(() => {
    setLoading(true);
    fetchData(() => {
      setLoading(false);
    }, showError);
  }, [showError]);

  const rows = {
    header: [t.givenName, t.familyName, t.email, t.username, t.role],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      { value: val.givenName },
      { value: val.familyName },
      { value: val.email },
      { value: val.username },
      { value: val.role },
    ],
  };

  const onSave = (evt) => {
    evt.preventDefault();

    const onUpdateResult = (res, err) => {
      if (!err) {
        fetchData((result) => {
          onFilterRecords(result, filters);
        }, showError);
        setOpen(false);
        setForm({});
      } else {
        showError(err);
      }
    };

    if (form.id) {
      if (pressedBtn === EDIT_USER) {
        updateUser(form, (result, err) => {
          onUpdateResult(result, err);
        });
      } else if (pressedBtn === DELETE_USER) {
        deleteUser(form, (result, err) => {
          onUpdateResult(result, err);
        });
      }
    } else {
      createUser(form, (result, err) => {
        onUpdateResult(result, err);
      });
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onSelectOption = (_, val, param) => {
    const result = selectOption(_, val, param, form);

    setForm(result);
  };

  if (loading) {
    return <Loading />;
  }

  let formItems = [];

  if (pressedBtn !== DELETE_USER) {
    formItems = [
      {
        select: true,
        label: "User Source",
        options: USER_SOURCE,
        option: "name",
        param: "source",
        key: "name",
        specificDetails: {
          getOptionDisabled: (option) => +option.id !== 1 && +option.id !== 2,
        },
      },
      ...(form?.source !== "GOOGLE"
        ? [
            {
              label: t.givenName,
              name: "givenName",
              disabled: form?.source !== "LOCAL",
            },
            {
              label: t.familyName,
              name: "familyName",
              disabled: form?.source !== "LOCAL",
            },
          ]
        : []),
      {
        label: t.email,
        name: "email",
        disabled: form?.source !== "LOCAL",
      },
      ...(form?.source !== "GOOGLE"
        ? [
            {
              label: t.username,
              name: "username",
              disable: form?.source !== "LOCAL",
            },
          ]
        : []),
      {
        select: true,
        label: t.role,
        options: USERS_ROLES,
        option: "name",
        param: "role",
        key: "name",
      },
      ...(form.id
        ? [
            {
              select: true,
              label: t.status,
              options: USER_STATUS,
              option: "name",
              param: "enabled",
              key: "enabled",
            },
          ]
        : []),
      ...(form?.source === "LOCAL" && !form.id
        ? [{ label: t.password, name: "password", password: true }]
        : []),
    ];
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        onSave={onSave}
        handleClose={onClose}
        title={
          pressedBtn === ADD_USER
            ? t.add(t.user)
            : pressedBtn === EDIT_USER
            ? t.edit(t.user) + (form?.source ? ` (${form?.source})` : "")
            : t.delete(t.user)
        }
        closeLabel={t.close}
        saveLabel={t.save}
        content={
          pressedBtn !== DELETE_USER && (
            <Box
              sx={{
                display: "grid",
                gap: "1rem",
              }}
            >
              {formItems.map((item, itemIdx) => {
                const input = (currentItem) => (
                  <Fragment key={currentItem.name}>
                    {!currentItem.select ? (
                      <TextField
                        sx={{
                          ...(form.id && currentItem.disable
                            ? { pointerEvents: "none" }
                            : {}),
                        }}
                        id={`outlined-basic-${currentItem.name}`}
                        label={currentItem.label}
                        name={currentItem.name}
                        value={form[currentItem.name] || ""}
                        onChange={onChangeTxt}
                        type={
                          showPassword || !currentItem.password
                            ? "text"
                            : "password"
                        }
                        InputProps={{
                          ...(currentItem.password
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }
                            : {}),
                        }}
                        size="small"
                      />
                    ) : (
                      <Select
                        options={currentItem.options}
                        onChange={(evt, val) =>
                          onSelectOption(
                            evt,
                            val?.[currentItem?.key],
                            currentItem.param
                          )
                        }
                        label={currentItem.label}
                        item={currentItem.option}
                        value={
                          currentItem.options.find(
                            (it) =>
                              form?.[currentItem.param] === it[currentItem.key]
                          ) || ""
                        }
                        specificDetails={currentItem.specificDetails}
                      />
                    )}
                  </Fragment>
                );

                return input(item);
              })}
            </Box>
          )
        }
      />

      <Button
        id={ADD_USER}
        sx={{ mb: 2, ...styleButton }}
        variant="contained"
        onClick={onOpen}
      >
        {t.addNew}
      </Button>
      <Table
        rows={{
          ...rows,
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        actionBtns={(item) => (
          <IconButton
            sx={{ padding: 0 }}
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();

              openPopup(item, e.currentTarget);
            }}
          >
            <MoreHoriz sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{ page, rowsPerPage, onChangePage, onChangeRowsPerPage }}
      />

      {openPopupForItem && (
        <div ref={popupRef} onMouseEnter={() => {}} {...{ onMouseLeave }}>
          <PopupWindow
            specificDetails={{
              onClose: closePopup,
              popupPosition: popupPosition,
              style: { div: { width: "140px" } },
              actions: [
                {
                  id: EDIT_USER,
                  label: t.update,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },
                {
                  id: DELETE_USER,
                  label: t.destroy,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },
              ]?.map((item, idx) => (
                <MenuItem
                  sx={{ ...styleTableMenuOption }}
                  key={idx}
                  id={item.id}
                  onClick={(evt) => {
                    if (item.onClick) {
                      item.onClick(evt);
                      closePopup();
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              )),
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default withErrorHandling(Users);
