import React from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { TextField, Autocomplete, Checkbox } from "@mui/material";

export default function Select(props) {
  const {
    options,
    onChange,
    label,
    item,
    value,
    multiple,
    specificDetails,
    disableCloseOnSelect,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      filterOptions={specificDetails?.filterOptions ? (x) => x : undefined}
      autoComplete={specificDetails?.autofill}
      includeInputInList={specificDetails?.includeInputInList}
      filterSelectedOptions={specificDetails?.filterSelectedOptions}
      noOptionsText="No options"
      onInputChange={specificDetails?.onInputChange}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={props.key}
          style={{
            ...(specificDetails?.styles?.elemLi
              ? specificDetails?.styles?.elemLi(option)
              : {}),
          }}
        >
          {multiple && (
            <Checkbox
              {...{ icon, checkedIcon }}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option[item]}
        </li>
      )}
      style={{
        ...specificDetails?.styles?.autocomplete,
      }}
      {...{ options }}
      getOptionDisabled={specificDetails?.getOptionDisabled}
      getOptionLabel={(option) =>
        specificDetails?.getOptionLabel
          ? specificDetails?.getOptionLabel?.value
          : option[item] ?? ""
      }
      {...{ value }}
      {...{ onChange, disableCloseOnSelect, multiple }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...{ label }}
          inputProps={{
            ...params.inputProps,
            style: { ...specificDetails?.styles?.input },
            ...specificDetails?.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            ...specificDetails?.InputProps,
            style: {
              ...specificDetails?.styles?.text,
            },
          }}
          variant={specificDetails?.variant}
          size="small"
        />
      )}
    />
  );
}
