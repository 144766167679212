import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ExpandLess, KeyboardArrowRight } from "@mui/icons-material";

import {
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  List,
  ListItem,
  IconButton,
  Box,
} from "@mui/material";

export default function NavItems(props) {
  const {
    navItems,
    open: openedSideMenu,
    onToggleMenuList: onOpen,
    toggledMenuList: open,
    openPopup,
    buttonRef,
  } = props;

  const location = useLocation();

  return (
    <>
      {navItems.map((item, itemIdx) => {
        const Icon = item.icon;

        return (
          <Fragment key={itemIdx}>
            <ListItem
              sx={{
                ...(location.pathname === item.to
                  ? { background: (theme) => theme.palette.primary.activeLink }
                  : {}),
                pl: 0,
                pr: 0,
              }}
              key={itemIdx}
            >
              <ListItemButton
                component={item.to && NavLink}
                to={item.to}
                key={itemIdx}
                sx={{ ...(!openedSideMenu ? { display: "grid" } : {}) }}
              >
                {item.icon && (
                  <Icon
                    style={{
                      ...(!openedSideMenu ? { padding: "0 !important" } : {}),
                      fill: (theme) => theme.palette.primary.text,
                      marginRight: "0.5rem",
                    }}
                  />
                )}
                <ListItemText
                  primary={item.label}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: !openedSideMenu ? "0.7rem" : "13px",
                    },
                  }}
                />
              </ListItemButton>
              {item.subItems && (
                <ListItemSecondaryAction>
                  <IconButton
                    ref={buttonRef}
                    sx={{
                      color: (theme) => theme.palette.primary.text,
                      right: 0,
                      ...(!openedSideMenu ? { marginRight: "-1rem" } : {}),
                    }}
                    onClick={(e) => {
                      e.stopPropagation();

                      openedSideMenu
                        ? onOpen(item.id)
                        : openPopup(item, e.currentTarget);
                    }}
                  >
                    {open[item.id] && openedSideMenu ? (
                      <ExpandLess />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {openedSideMenu && (
              <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                <List component="ul">
                  <Box
                    sx={{
                      position: "absolute",
                      height: "100%",
                      background: (theme) => theme.palette.primary.activeLink,
                      width: "2px",
                      left: "1.5rem",
                      top: 0,
                    }}
                  ></Box>
                  {item.subItems?.map((subItem, itemIdx) => (
                    <ListItemButton
                      key={itemIdx}
                      component={NavLink}
                      sx={{
                        ...(location.pathname === subItem.to
                          ? {
                              background: (theme) =>
                                theme.palette.primary.activeLink,
                            }
                          : {}),
                      }}
                      to={subItem.to}
                      role="menuitem"
                      aria-label={`Navigate to ${subItem.label}`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "5px",
                          height: "5px",
                          backgroundColor: (theme) =>
                            theme.palette.primary.activeLink,
                          borderRadius: "50%",
                          marginRight: "0.5rem",
                          marginLeft: "2rem",
                        }}
                      ></Box>
                      <ListItemText
                        primary={subItem.label}
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "13px",
                          },
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
