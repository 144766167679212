import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import { Menu } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  List,
  ListItemText,
  ListItemButton,
  Avatar,
  Popper,
  ClickAwayListener,
  Paper,
  IconButton,
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  Badge,
} from "@mui/material";

import t from "../helpers/en";
import { filterRecords, changeTxt } from "../helpers/selectors";
import {
  getProjects,
  getUsers,
  newProject,
  deleteProject,
  updateProject,
} from "../helpers/utils";

import Search from "./Inputs/SearchInput";
import Modal from "./Modal/Modal";
import CheckboxGroup from "./Inputs/CheckboxGroup";
import Radio from "./Inputs/RadioGroup";

import { styleIconBtn, styleEllipsis } from "../GeneralStyles";

const groupStyles = {
  display: "grid",
  gap: "1rem",
  alignItems: "center",
  borderBottom: "1px solid rgba(0,0,0,.5)",
  gridTemplateColumns: "1fr 1fr 1fr repeat(2, 2em)",
  padding: "0.2rem",
};

const userStyles = {
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 5em 2em",
  borderBottom: "1px solid rgba(0,0,0,.5)",
  padding: "0.2rem",
};

const GroupBtn = ({ onOpen, modalData, currentGroup }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        ...styleEllipsis,
        maxWidth: "15em",
      }}
      onClick={onOpen}
    >
      {modalData?.isLoading ? (
        <CircularProgress
          style={{ width: "1em", height: "1em", color: "#fff" }}
        />
      ) : (
        currentGroup?.name ?? t.noProject
      )}
    </Button>
  );
};

const AppBarMain = (props) => {
  const { onLogout, currentUser, showError, viewport, onToggleDrawer } = props;

  const isSmallScreen = viewport < 1200;

  const { onSetGroup, currentGroup } = useSharedProps();
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorAvatarEl, setAnchorAvatarEl] = useState(null);
  const [modalData, setModalData] = useState({ open: false, tabValue: 0 });
  const [data, setData] = useState({ all: [], filtered: [] });

  const onOpenAvatarPopper = (evt) => {
    setAnchorAvatarEl(anchorAvatarEl ? null : evt.currentTarget);
  };

  const onCloseAvatarPopper = () => {
    setAnchorAvatarEl(null);
  };
  const openAvatarPopup = Boolean(anchorAvatarEl);
  const id = openAvatarPopup ? "AVATAR_POPPER" : undefined;

  const currentUserAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#fff",
        color: (theme) => theme.palette.primary.main,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const onMove2Users = (cb) => {
    cb && cb();
    navigate("/users");
  };

  const fetchData = (params, cb) => {
    getProjects((result, errorMsg) => {
      if (result) {
        const updated = result.map((group) => {
          const isContributor = group?.userRights?.find(
            (user) => user?.user?.id === params.currentUser?.uid
          )?.isContributor;

          return {
            ...group,
            isContributor2Group: isContributor,
          };
        });

        params.setData({ all: updated, filtered: updated });

        if (cb) cb(updated);
      } else {
        params.showError(errorMsg);
      }
    });
  };

  useEffect(() => {
    fetchData({ setData, showError, currentUser }, (result) => {
      if (!sessionStorage.getItem("projectGroupSetOnLogin")) {
        const defaultProject =
          Array.isArray(result) &&
          result?.find((it) => it.name?.toLowerCase() === "default");

        sessionStorage.setItem("projectGroupSetOnLogin", true);

        if (defaultProject) {
          onSetGroup(defaultProject);
        }
      }
    });
  }, [showError, setData, currentUser, onSetGroup]);

  const onOpen = (evt) => {
    setModalData({
      open: true,
      tabValue: 0,
      options: [],
      selectedUsers: [],
    });
  };

  const onClose = () => {
    setModalData({ open: false });
  };

  const onChangeTab = (_, val) => {
    setModalData((prev) => ({
      ...prev,
      tabValue: val,
      id: null,
      name: null,
      selectedUsers: modalData?.id
        ? []
        : [
            {
              ...({ ...currentUser, id: currentUser?.uid } ?? {}),
              isContributor: true,
            },
          ],
    }));
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [item.name]);

    setData(res);
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setModalData((prev) => ({ ...prev, searchedValue: value }));
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...modalData, searchedValue: null };

    setModalData(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, modalData);
    }
  };

  const onChangeSearchTxtUser = (evt) => {
    const { value } = evt.target;

    setModalData((prev) => ({ ...prev, searchedUser: value }));
  };

  const onClearSearchTxtUser = () => {
    const copyFilters = { ...modalData, searchedUser: null };

    setModalData(copyFilters);
  };

  const onKeyPressSearchUser = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      if (modalData?.searchedUser) {
        setModalData((prev) => ({
          ...prev,
          isSearchingUser: true,
        }));
        getUsers(
          (result, err) => {
            if (!err) {
              setModalData((prev) => ({
                ...prev,
                options: result
                  ? [result, ...modalData.options]
                  : modalData?.options,
                selectedUsers: [
                  ...prev.selectedUsers,
                  ...(result?.id &&
                  !prev.selectedUsers.some((it) => it.id === result?.id)
                    ? [result]
                    : []),
                ],
                searchedUser: null,
                isSearchingUser: false,
              }));
            } else {
              showError(err);
              setModalData((prev) => ({
                ...prev,
                isSearchingUser: false,
              }));
            }
          },
          { email: modalData?.searchedUser, shouldSearchByEmail: true }
        );
      }
    }
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, modalData);

    setModalData(result);
  };

  const onCheckboxBtnChange = (evt, item, idx) => {
    let cpy = { ...modalData };

    cpy.selectedUsers[idx] = {
      ...cpy.selectedUsers[idx],
      isContributor: !item.isContributor,
    };

    setModalData(cpy);
  };

  const setProject = () => {
    const triggerUpdate = (result, err) => {
      if (!err) {
        onClose();
      } else {
        showError(err);
      }
    };

    if (!modalData?.id) {
      newProject(modalData, (result, err) => {
        triggerUpdate(result, err);
      });
    } else {
      updateProject(modalData, (result, err) => {
        triggerUpdate(result, err);
      });
    }
  };

  const onEditGroup = (_, el) => {
    setModalData((prev) => ({
      ...prev,
      tabValue: 1,
      name: el.name,
      id: el.id,
      selectedUsers: el.userRights?.map((it) => ({
        ...it.user,
        isContributor: it.isContributor,
      })),
    }));
  };

  const onDeleteGroup = (el) => {
    deleteProject(el, (result, err) => {
      if (!err) {
        fetchData();
        if (el?.id === currentGroup?.id) {
          onSetGroup(el);
        }
      } else {
        showError(err);
      }
    });
  };

  const onRemoveUser = (idx) => {
    const cpy = { ...modalData };

    cpy?.selectedUsers?.splice(idx, 1);
    setModalData(cpy);
  };

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          background: "#fff",
          display: "flex",
          padding: "0.5rem 1rem",
          height: "3.5em",
          top: 0,
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          {!isSmallScreen && (
            <img
              style={{ maxHeight: "80px" }}
              src={process.env.PUBLIC_URL + "/logo96.png"}
              alt="logo"
            />
          )}
          <IconButton
            onClick={onToggleDrawer}
            sx={{
              background: (theme) => theme.palette.primary.main,
              borderRadius: "5px",
              height: "1.502em",
              ...(!isSmallScreen
                ? { position: "absolute", marginLeft: "245px" }
                : {}),
            }}
            disableRipple
          >
            <Menu sx={{ fill: "#fff" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              ...(!isSmallScreen
                ? { position: "absolute", marginLeft: "300px" }
                : { alignItems: "center", marginLeft: "1rem" }),
            }}
          >
            {/* <Box
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              letterSpacing: "0.02857em",
              padding: "6px 16px",
              margin: "0 1rem",
            }}
          >
            Kubernetes
          </Box> */}
            {currentGroup?.id ? (
              <Badge
                badgeContent={
                  currentGroup?.isContributor2Group ? (
                    <EditIcon sx={{ padding: "0.2rem" }} />
                  ) : (
                    <VisibilityIcon sx={{ padding: "0.2rem" }} />
                  )
                }
                color="info"
              >
                <GroupBtn {...{ onOpen, modalData, currentGroup }} />
              </Badge>
            ) : (
              <GroupBtn {...{ onOpen, modalData, currentGroup }} />
            )}
          </Box>

          <IconButton sx={{ marginLeft: "auto" }} onClick={onOpenAvatarPopper}>
            <Avatar
              {...currentUserAvatar(
                `${currentUser?.givenName} ${currentUser?.familyName}`
              )}
              sx={{ pointerEvents: "none" }}
            />
          </IconButton>
        </Box>
      </Box>

      {/* POPPER */}

      <Popper
        sx={{ zIndex: 10000 }}
        {...{ id }}
        anchorEl={anchorAvatarEl}
        open={openAvatarPopup}
        onClose={onCloseAvatarPopper}
        disablePortal={true}
      >
        <ClickAwayListener onClickAway={onCloseAvatarPopper}>
          <Paper>
            <List>
              {[
                { name: t.myProfile },
                ...(currentUser?.role === "ADMIN" ||
                currentUser?.role === "SYSTEM"
                  ? [
                      {
                        name: t.users,
                        onClick: () => onMove2Users(onCloseAvatarPopper),
                      },
                    ]
                  : []),
                {
                  name: t.logout,
                  onClick: () =>
                    onLogout(() => {
                      navigate("/login", { state: { from: location } });
                      onCloseAvatarPopper();
                    }),
                },
              ].map((it, itIdx) => (
                <ListItemButton key={itIdx} onClick={it.onClick || undefined}>
                  <ListItemText>{it.name}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>

      {/* MODAL */}

      <Modal
        open={Boolean(modalData?.open)}
        handleClose={onClose}
        title={t.manageProjects}
        saveLabel={modalData?.tabValue === 1 && t.save}
        onSave={modalData?.tabValue === 1 && setProject}
        closeLabel={t.close}
        content={
          <Box>
            <Tabs
              value={modalData?.tabValue}
              onChange={onChangeTab}
              aria-label="select a group"
              sx={{ mb: 2 }}
            >
              {[
                { id: 0, label: t.selectProject },
                {
                  id: 1,
                  label: !modalData?.id ? t.newProject : t.edit(t.project),
                },
              ].map((tab, tabKey) => (
                <Tab
                  key={tabKey}
                  label={tab.label}
                  id={`group-tab-${tab.id}`}
                  aria-controls={`group-tabpanel-${tab.id}`}
                />
              ))}
            </Tabs>
            {modalData?.tabValue === 0 ? (
              <>
                <Search
                  className={{
                    marginLeft: "auto",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                  searchedValue={modalData?.searchedValue}
                  {...{
                    onChangeSearchTxt,
                    onClearSearchTxt,
                    onKeyPressSearch,
                  }}
                  specificDetails={{
                    styles: {
                      searchIcon: { width: "1.5em", height: "auto" },
                    },
                  }}
                />
                {data.all?.length > 0 ? (
                  <>
                    <span style={groupStyles}>
                      {[t.name, t.id, t.userRights].map((it) => (
                        <span style={{ fontWeight: 500 }} key={it}>
                          {it}
                        </span>
                      ))}
                    </span>
                    {data?.filtered?.map((it) => (
                      <span key={it.id} style={groupStyles}>
                        <span
                          onClick={() => onSetGroup(it)}
                          style={{
                            cursor: "pointer",
                            alignContent: "center",
                            width: "100%",
                          }}
                        >
                          <Radio
                            onChange={() => onSetGroup(it)}
                            specificDetails={{
                              isIndividual: true,
                              checked: it.id === currentGroup?.id,
                              value: it.id,
                            }}
                          />
                          {it.name}
                        </span>
                        <span>{it.id}</span>
                        <span>
                          {it?.isContributor2Group ? t.contributor : t.readOnly}
                        </span>
                        <IconButton
                          sx={{
                            ...styleIconBtn,
                            width: "1.5em",
                            height: "1.5em",
                          }}
                          title={t.edit(t.appProject)}
                          onClick={(evt) => onEditGroup(evt, it)}
                          disabled={!it?.isContributor2Group}
                        >
                          <EditIcon sx={{ pointerEvents: "none" }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            ...styleIconBtn,
                            width: "1.5em",
                            height: "1.5em",
                          }}
                          title={t.delete(t.appProject)}
                          onClick={() => onDeleteGroup(it)}
                          disabled={!it?.isContributor2Group}
                        >
                          <DeleteIcon sx={{ pointerEvents: "none" }} />
                        </IconButton>
                      </span>
                    ))}
                  </>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      marginTop: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    {t.noProject}
                  </span>
                )}
              </>
            ) : (
              <span style={{ display: "grid", gap: "1rem" }}>
                <TextField
                  id={`outlined-basic-name`}
                  label={t.projectN}
                  name="name"
                  value={modalData?.name || ""}
                  onChange={onChangeTxt}
                  size="small"
                />
                <Search
                  searchedValue={modalData?.searchedUser}
                  onChangeSearchTxt={onChangeSearchTxtUser}
                  onClearSearchTxt={onClearSearchTxtUser}
                  onKeyPressSearch={onKeyPressSearchUser}
                  specificDetails={{
                    endIc: modalData?.isSearchingUser && <CircularProgress />,
                    placeholder: "Search User by Email",
                    styles: {
                      searchIcon: { width: "1.5em", height: "auto" },
                      paper: {
                        width: "100%",
                        marginBottom: "1rem",
                        boxShadow: "none",
                        border: "1px solid rgba(0,0,0,0.3)",
                      },
                    },
                  }}
                />
                {modalData?.selectedUsers?.length > 0 && (
                  <>
                    <span style={userStyles}>
                      {[t.users, "Contributor"].map((it, itIdx) => (
                        <span style={{ fontWeight: 500 }} key={itIdx}>
                          {it}
                        </span>
                      ))}
                    </span>
                    <span style={{ display: "grid", gap: "1rem" }}>
                      {modalData?.selectedUsers?.map((it, idx) => (
                        <span key={it.id} style={userStyles}>
                          {idx + 1}. {it.email}
                          <CheckboxGroup
                            specificDetails={{
                              isSingleInput: true,
                              checked: it.isContributor,
                              name: "isContributor",
                              disabled: it?.id === currentUser?.uid,
                              formControlLabelStyle: { margin: 0 },
                              checkboxStyle: {
                                padding: 0,
                                "& .MuiSvgIcon-root": {
                                  width: "1.5em",
                                  height: "auto",
                                },
                              },
                            }}
                            onChange={(evt) =>
                              onCheckboxBtnChange(evt, it, idx)
                            }
                          />
                          <IconButton
                            onClick={() => {
                              onRemoveUser(idx);
                            }}
                            sx={{
                              ...styleIconBtn,
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            disabled={
                              it?.id === modalData?.currentLoggedInUser?.id
                            }
                          >
                            <RemoveIcon sx={{ pointerEvents: "none" }} />
                          </IconButton>
                        </span>
                      ))}
                    </span>
                  </>
                )}
              </span>
            )}
          </Box>
        }
      />
    </>
  );
};

export default withErrorHandling(AppBarMain);
