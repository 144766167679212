import React from "react";

import { Drawer, Toolbar, List, IconButton } from "@mui/material";

import { Close } from "@mui/icons-material";

const DRAWER_WIDTH_EXPANDED = 300;
const DRAWER_WIDTH_COLLAPSED = 100;

export default function DrawerMain(props) {
  const { open, onToggleDrawer, viewport, menuList } = props;

  const isSmallScreen = viewport < 1200;

  return (
    <Drawer
      variant={isSmallScreen ? "temporary" : "persistent"}
      {...{ open }}
      anchor="left"
      onClose={isSmallScreen ? onToggleDrawer : undefined}
      ModalProps={{ ...(isSmallScreen ? { keepMounted: true } : {}) }}
      sx={{
        height: "100%",
        ...(!isSmallScreen
          ? {
              width: open ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED,
              flexShrink: 0,
              position: "fixed",
              "& .MuiDrawer-paper": {
                width: open ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED,
                transition: "width 0.3s",
                boxShadow: "none",
                visibility: "visible !important",
                transform: "none !important",
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.text,
              },
            }
          : {
              width: open ? DRAWER_WIDTH_EXPANDED : 0,
              "& .MuiDrawer-paper": {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.text,
                width: open ? DRAWER_WIDTH_EXPANDED : 0,
                transition: "width 0.3s",
              },
            }),
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "0.5rem 1rem",
          height: "3.5em !important",
          minHeight: "unset !important",
          background: "#fff",
        }}
      >
        <img
          style={{
            margin: "auto",
          }}
          src={process.env.PUBLIC_URL + "/logo96.png"}
          alt="logo"
        />
        <IconButton onClick={onToggleDrawer}>
          <Close />
        </IconButton>
      </Toolbar>
      <List sx={{ overflow: "auto", padding: 0 }} component="nav">
        {menuList}
      </List>
    </Drawer>
  );
}
