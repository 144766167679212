import React, { useState, useEffect } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import { MoreHoriz } from "@mui/icons-material";

import { Box, IconButton, MenuItem } from "@mui/material";

import t from "../helpers/en";
import { fetchLogs, getBuilds, deleteBuild } from "../helpers/utils";
import {
  AUDIT_LOGS,
  DELETE_ITEM,
  JSON_ITEM,
  FALSE,
} from "../helpers/constants";
import { filterRecords } from "../helpers/selectors";
import usePopup from "../helpers/contexts/usePopup";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import PopupWindow from "../components/PopupWindow";

import {
  styleEllipsis,
  styleTableMenuOption,
  styleStatus,
} from "../GeneralStyles";

const rowsParams = (data) => ({
  header: [t.name, t.number, t.image, t.namespace, t.succeeded],
  body: data?.filtered,
  bodyCount: data?.filtered,
  bodyParams: (val) => [
    {
      value: val?.metadata?.displayName ?? val?.metadata?.name,
    },
    { value: val?.metadata?.generation },
    {
      value: val?.status?.latestImage,
      specialDetails: {
        hasTooltip: true,
        isUrl: true,
        ellipsis: { maxWidth: "10em", ...styleEllipsis },
      },
    },
    {
      value: val?.metadata?.namespace,
    },
    {
      value: (
        <span
          style={{
            ...styleStatus(val?.status?.conditions?.[0]?.status === FALSE),
          }}
        >
          {val?.status?.conditions?.[0]?.status}
        </span>
      ),
    },
  ],
});

const ViewBuilds = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    all: [],
    filtered: [],
  });
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();
  const {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    onMouseLeave,
    openPopupForItem,
  } = usePopup(80);

  const fetchData = (cb, onError) => {
    getBuilds(null, (result, err) => {
      if (result) {
        setData({ all: result, filtered: result });
      } else {
        onError(err);
      }

      cb && cb(result);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(() => setIsLoading(false), showError);
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const currentTargetID = evt.target.id;

    if (currentTargetID === AUDIT_LOGS) {
      fetchLogs(
        {
          ...row,
          imageName: row?.metadata?.name,
          buildNumber: row?.metadata?.generation,
          namespace: row?.metadata?.namespace,
        },
        (result, err) => {
          if (!err) {
            setForm(result);
            setOpen(true);
          } else {
            showError(err);
          }
        }
      );
    } else {
      const modalData = { ...row };

      setForm(modalData);
      setOpen(true);
    }

    setPressedBtn(currentTargetID);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => {
      return [item?.metadata?.name];
    });

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };
  /*
  const onPrint = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("logs").innerText], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "logs.log";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
*/
  const onDelete = (evt, row) => {
    deleteBuild(row.metadata.name, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    handleClose();
  };

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{
              height: "550px",
              width: "100%",
              overflow: "scroll",
            }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case AUDIT_LOGS:
      title = t.events;
      saveLabel = t.downloadLogs;
      // onConfirmModalAction = onPrint;
      content = (
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          <code id="logs">{form}</code>
        </pre>
      );
      break;
    case DELETE_ITEM:
      title = t.delete(t.build);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    default:
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />
      <Table
        rows={{
          ...(rowsParams && rowsParams(data)),
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => {
          return item?.metadata?.name === form?.metadata?.name;
        }}
        actionBtns={(item) => (
          <IconButton
            sx={{ padding: 0 }}
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();

              openPopup(item, e.currentTarget);
            }}
          >
            <MoreHoriz sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
      />

      {openPopupForItem && (
        <div ref={popupRef} onMouseEnter={() => {}} {...{ onMouseLeave }}>
          <PopupWindow
            specificDetails={{
              onClose: closePopup,
              popupPosition: popupPosition,
              style: { div: { width: "140px" } },
              actions: [
                {
                  id: JSON_ITEM,
                  label: t.json,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },
                {
                  id: AUDIT_LOGS,
                  label: `${t.view} ${t.logs}`,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                },

                {
                  id: DELETE_ITEM,
                  label: t.destroy,
                  onClick: (evt) => onOpen(evt, openPopupForItem),
                  disabled:
                    currentGroup?.id && !currentGroup?.isContributor2Group,
                },
              ]?.map((item, idx) => (
                <MenuItem
                  sx={{ ...styleTableMenuOption }}
                  key={idx}
                  id={item.id}
                  onClick={(evt) => {
                    if (item.onClick) {
                      item.onClick(evt);
                      closePopup();
                    }
                  }}
                  disabled={item.disabled}
                >
                  {item.label}
                </MenuItem>
              )),
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default withErrorHandling(ViewBuilds);
