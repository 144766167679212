import React, { createContext, useContext, useState } from "react";

const SharedPropsContext = createContext();

export const SharedPropsProvider = ({ children }) => {
  const [currentGroup, setCurrentGroup] = useState(
    JSON.parse(sessionStorage.getItem("appGroupId"))
  );

  const onSetGroup = (el) => {
    if (el?.id !== currentGroup?.id) {
      setCurrentGroup(el);
      sessionStorage.setItem("appGroupId", JSON.stringify(el));
    } else if (el?.id === currentGroup?.id) {
      setCurrentGroup(null);
      sessionStorage?.removeItem("appGroupId");
    }
  };

  return (
    <SharedPropsContext.Provider value={{ onSetGroup, currentGroup }}>
      {children}
    </SharedPropsContext.Provider>
  );
};

export const useSharedProps = () => useContext(SharedPropsContext);
