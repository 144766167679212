import React, { useState } from "react";

import withErrorHandling from "../HOC/ErrorHandling";

import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Loading from "../components/Loading";

import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";

import t from "../helpers/en";
import { authWithCustomLogin, authWithGoogle } from "../helpers/utils";

const SIMPLE_LOGIN = "SIMPLE_LOGIN";
const GOOGLE_LOGIN = "GOOGLE_LOGIN";

const Login = (props) => {
  const { onLoginResponse, showError } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const navigate2AfterLogin =
    location?.state?.from?.pathname !== "/"
      ? location?.state?.from?.pathname
      : "/";

  const onChangeTxt = (evt) => {
    const { name, value } = evt.target;
    let cpyFrom = { ...form };
    cpyFrom[name] = value;

    setForm(cpyFrom);
  };

  const onLogin = (btnName, credentialResponse, cb) => {
    setLoading(true);
    if (btnName === "GOOGLE_LOGIN") {
      authWithGoogle(credentialResponse, (response, status, err) => {
        setLoading(false);
        if (!err) {
          onLoginResponse(response, status, cb);
        } else {
          showError(err);
        }
      });
    } else {
      authWithCustomLogin(credentialResponse, (result, status, err) => {
        setLoading(false);
        if (!err) {
          onLoginResponse(result, status, cb);
        } else {
          showError(err);
        }
      });
    }
  };

  const onToggleShowPassword = () => setShowPassword((show) => !show);

  if (sessionStorage.getItem("token")) {
    return <Navigate to={location?.state?.from?.pathname ?? "/"} replace />;
  }

  return (
    <Box
      sx={{
        display: { sm: "flex", md: "grid" },
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "1rem",
        padding: "1rem",
        width: "fit-content",
        borderRadius: "0.2rem",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <span style={{ display: "flex", flexDirection: "column" }}>
        <img
          style={{ width: "7em", display: "flex", alignSelf: "center" }}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="logo.png"
        />
        <h4>{t.signin}</h4>
        <Typography sx={{ mb: 3 }}>{t.continue2}</Typography>
      </span>
      <span style={{ display: "grid", gridRowGap: "1rem" }}>
        <TextField
          label={t.username}
          name="username"
          value={form.username || ""}
          onChange={onChangeTxt}
          size="small"
        />
        <TextField
          label={t.password}
          name="password"
          type={showPassword ? "text" : "password"}
          value={form.password || ""}
          onChange={onChangeTxt}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onToggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
        />
        <Button
          variant="contained"
          onClick={() => {
            onLogin(SIMPLE_LOGIN, form, () => {
              navigate(navigate2AfterLogin);
            });
          }}
          disabled={loading}
        >
          {loading ? <Loading size={24} /> : t.signin}
        </Button>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            onLogin(GOOGLE_LOGIN, credentialResponse, () => {
              navigate(navigate2AfterLogin);
            });
          }}
          onError={() => {
            console.error("Login Failed");
          }}
        />
      </span>
    </Box>
  );
};

export default withErrorHandling(Login);
