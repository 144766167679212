import React from "react";

import AddIcon from "@mui/icons-material/Add";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Delete as DeleteIcon } from "@mui/icons-material";

import {
  InputAdornment,
  Tabs,
  Tab,
  IconButton,
  TextField,
} from "@mui/material";

import t from "../helpers/en";
import {
  DELETE_VARIABLE,
  ADD_VARIABLE,
  JSON_ADD_VARIABLES,
  EDIT_ITEM,
} from "../helpers/constants";

import Table from "./Table/Table";
import Modal from "./Modal/Modal";

import { styleIconBtn } from "../GeneralStyles";

const rowsEditTable = (data, onChange, specificDetails) => ({
  header: [t.key, t.value],
  body: data?.[specificDetails.param],
  bodyParams: (val, valIdx) => [
    {
      value: (
        <TextField
          sx={{ width: "100%" }}
          id={`outlined-basic-key_${valIdx}`}
          name={"key"}
          value={val.key || ""}
          onChange={(e) => onChange(e, val, valIdx, specificDetails.param)}
          size="small"
        />
      ),
    },
    {
      value: (
        <TextField
          sx={{ width: "100%" }}
          id={`outlined-basic-value_${valIdx}`}
          name={"value"}
          type={
            specificDetails.param === "secrets" &&
            !val.isPwdVisible &&
            data?.targetID === EDIT_ITEM
              ? "password"
              : "text"
          }
          value={val.value || ""}
          onChange={(e) => onChange(e, val, valIdx, specificDetails.param)}
          InputProps={{
            ...(specificDetails.param === "secrets" &&
            !val.isPwdVisible &&
            data?.targetID === EDIT_ITEM &&
            (specificDetails.userData?.role === "SYSTEM" ||
              specificDetails.userData?.role === "ADMIN")
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) =>
                          specificDetails.onShowPwd(e, {
                            form: data,
                            item: {
                              ...val,
                              valIdx,
                              paramName: specificDetails.param,
                            },
                          })
                        }
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <VisibilityOff />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          size="small"
        />
      ),
    },
  ],
});

const SetConfigsAndSecrets = (props) => {
  const {
    form,
    onChangeTab,
    onChangeSubTxt,
    onSetConfigsAndSecrets,
    onChangeTxtJson,
    onSetJsonVariable2Table,
    onHideModalSecond,
    onOpenSecond,
    onShowPwd,
  } = props;

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  return (
    <>
      <Tabs
        value={form?.tabVal}
        onChange={onChangeTab}
        aria-label="configs and secrets"
        sx={{ mb: 2 }}
      >
        {[
          { id: 0, label: t.configs },
          { id: 1, label: t.secrets },
        ].map((tab, tabKey) => (
          <Tab
            xs={{ width: "50%" }}
            key={tabKey}
            label={tab.label}
            id={`simple-tab-${tab.id}`}
            aria-controls={`simple-tabpanel-${tab.id}`}
          />
        ))}
      </Tabs>
      {form?.tabVal === 0 ? (
        <Table
          rows={
            rowsEditTable &&
            rowsEditTable(form, onChangeSubTxt, { param: "variables" })
          }
          selectedRow={() => false}
          hideTableHeader
          specificDetails={{
            topElem: (
              <span
                style={{
                  margin: "0.5rem 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t.variables}
                <IconButton
                  sx={{
                    ...styleIconBtn,
                    ml: 2,
                    height: "1.5em",
                    width: "1.5em",
                  }}
                  id={ADD_VARIABLE}
                  title={t.add(t.variables)}
                  onClick={(e) =>
                    onSetConfigsAndSecrets(e, { form, param: "variables" })
                  }
                >
                  <AddIcon sx={{ pointerEvents: "none " }} />
                </IconButton>
                <IconButton
                  sx={{ ...styleIconBtn, ml: 2, height: "1.5em" }}
                  id={JSON_ADD_VARIABLES}
                  title={t.add(t.variables)}
                  onClick={(e) => onOpenSecond(e, "variables")}
                >
                  <AddIcon sx={{ pointerEvents: "none " }} /> json
                </IconButton>
              </span>
            ),
          }}
          actionBtns={(item, itemIdx) => (
            <>
              <IconButton
                id={DELETE_VARIABLE}
                title={t.delete(t.variable)}
                onClick={(evt) =>
                  onSetConfigsAndSecrets(evt, {
                    form,
                    item: { ...item, currentItemIdx: itemIdx },
                    param: "variables",
                  })
                }
              >
                <DeleteIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            </>
          )}
        />
      ) : (
        <Table
          rows={
            rowsEditTable &&
            rowsEditTable(form, onChangeSubTxt, {
              param: "secrets",
              onShowPwd,
              userData,
            })
          }
          selectedRow={() => false}
          hideTableHeader
          specificDetails={{
            topElem: (
              <span
                style={{
                  margin: "0.5rem 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t.secrets}
                <IconButton
                  sx={{
                    ...styleIconBtn,
                    ml: 2,
                    height: "1.5em",
                    width: "1.5em",
                  }}
                  id={ADD_VARIABLE}
                  title={t.add(t.variables)}
                  onClick={(e) =>
                    onSetConfigsAndSecrets(e, { form, param: "secrets" })
                  }
                >
                  <AddIcon sx={{ pointerEvents: "none " }} />
                </IconButton>
                <IconButton
                  sx={{ ...styleIconBtn, ml: 2, height: "1.5em" }}
                  id={JSON_ADD_VARIABLES}
                  title={t.add(t.variables)}
                  onClick={(e) => onOpenSecond(e, "secrets")}
                >
                  <AddIcon sx={{ pointerEvents: "none " }} /> json
                </IconButton>
              </span>
            ),
          }}
          actionBtns={(item, itemIdx) => (
            <>
              <IconButton
                id={DELETE_VARIABLE}
                title={t.delete(t.variable)}
                onClick={(evt) =>
                  onSetConfigsAndSecrets(evt, {
                    form,
                    item: { ...item, currentItemIdx: itemIdx },
                    param: "secrets",
                  })
                }
              >
                <DeleteIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            </>
          )}
        />
      )}

      {/* JSON MODAL */}
      <Modal
        open={Boolean(form?.secondModal?.open)}
        title={t.edit(t.variables)}
        content={
          <TextField
            id="outlined-json"
            value={form?.secondModal?.value || ""}
            onChange={onChangeTxtJson}
            rows={10}
            fullWidth
            multiline={true}
            size="small"
          />
        }
        saveLabel={t.confirm}
        closeLabel={t.close}
        onSave={onSetJsonVariable2Table}
        handleClose={onHideModalSecond}
      />
    </>
  );
};

export default SetConfigsAndSecrets;
