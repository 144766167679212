import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import withErrorHandling from "../HOC/ErrorHandling";

import { CopyBlock, dracula } from "react-code-blocks";

import { ReactComponent as Copy } from "../assets/imgs/copy.svg";
import { ReactComponent as Copied } from "../assets/imgs/copied.svg";
import { MoreHoriz } from "@mui/icons-material";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";

import t from "../helpers/en";
import {
  EDIT_ITEM,
  JSON_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
  AUDIT_LOGS,
  FORCE_BUILD,
  DELETE_ITEM,
  FALSE,
} from "../helpers/constants";
import {
  getBuilds,
  getImages,
  getk8sLogs,
  forceRebuild,
  deleteApplication,
  restartService,
  getConfigurationData2Export,
  getAppByImageUid,
  setWizard3,
} from "../helpers/utils";
import { filterRecords } from "../helpers/selectors";

import { useSharedProps } from "../HOC/SharedProps";

import ConfigureBuild from "./ConfigureBuild";
import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";

import {
  styleEllipsis,
  styleStatus,
  styleTableMenuOption,
} from "../GeneralStyles";

const rowsParams = (data) => ({
  header: [
    t.name,
    t.buildNum,
    t.latestBuildReason,
    t.latestImage,
    t.status,
    t.createdOn,
  ],
  body: data,
  bodyCount: data,
  bodyParams: (val) => [
    {
      value: val?.metadata?.displayName ?? val?.metadata?.name,
    },
    { value: val?.metadata?.labels?.["image.kpack.io/buildNumber"] },
    { value: val?.metadata?.annotations?.["image.kpack.io/reason"] },
    {
      value: val?.status?.latestImage,
      hasToolip: true,
      style: { maxWidth: "10em" },
      specialDetails: {
        hasTooltip: true,
        ellipsis: { maxWidth: "10em", ...styleEllipsis },
      },
    },
    {
      value: (
        <span
          style={{
            ...styleStatus(val?.status?.conditions?.[0]?.status === FALSE),
          }}
        >
          {val?.status?.conditions?.[0]?.status}
        </span>
      ),
    },
    {
      value: val?.metadata?.creationTimestamp,
    },
  ],
});

const ImageDetails = (props) => {
  const { showError, currentUser } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [form, setForm] = useState({});
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [pressedBtn, setPressedBtn] = useState(null);
  const [copied, setCopied] = useState({});
  const [anchorEl, setAnchorEl] = useState({});

  const { id } = useParams();
  const { currentGroup } = useSharedProps();
  const navigate = useNavigate();

  const fetchData = (cb, onError, param) => {
    const data1 = new Promise((resolve, reject) => {
      getBuilds({ imageName: param }, (result, err) => {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      });
    });
    const data2 = new Promise((resolve, reject) => {
      getImages({ name: param }, (result, err) => {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      });
    });

    Promise.all([data1, data2])
      .then(([result1, result2]) => {
        setData({ data: result1, filtered: result1, parent: result2 });

        cb && cb(result1);
      })
      .catch((err) => {
        onError(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const callback = (cb) => {
      fetchData(cb, showError, id);
    };

    callback(() => setIsLoading(false));
  }, [showError, id]);

  const onRestart = () => {
    let name = data?.parent?.metadata?.displayName?.replace("-image", "");

    restartService(name, (result, err) => {
      if (err) {
        showError(err);
      }
    });

    return;
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onOpen = (evt, row) => {
    const targetId = evt.target.id;
    let modalData = {};

    if (targetId === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result) => {
            resolve({ ...row, logs: [...(result.content ?? [])] });
          }
        );
      }).then((res) => setForm(res));
    } else if (targetId === FORCE_BUILD) {
      forceRebuild(row?.metadata, (result, err) => {
        if (err) {
          showError(err);
        }
      });
      return;
    } else if (targetId === EDIT_ITEM) {
      const imageUid = data?.parent?.metadata?.uid;
      getAppByImageUid(imageUid, (result, error) => {
        if (!error) {
          setForm({ isEditForm: true, ...result });
        }
      });
    } else {
      modalData = { ...(row ? { row } : {}) };

      setForm(modalData);
    }

    setPressedBtn(targetId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onSave = () => {
    setWizard3(form);
  };

  const copy2Clipboard = async (el) => {
    try {
      await navigator.clipboard.writeText(el.value);
      setCopied((prevState) => ({
        ...prevState,
        ...el,
        status: true,
      }));
    } catch {}
  };

  const onToggleMenuItem = (event, id) => {
    setAnchorEl((prev) => ({
      ...prev,
      [id]: !event ? null : event.currentTarget,
    }));
  };

  const onExportConfigurationData = () => {
    getConfigurationData2Export(
      { uid: data?.parent?.metadata?.uid },
      (result, err) => {
        if (!err) {
          const blob = new Blob([JSON.stringify(result, null, 2)], {
            type: "application/json",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "";

          link.click();
        }
      }
    );
  };

  const menuMore = [
    { label: "Export Configuration Data", onClick: onExportConfigurationData },
    { label: "Edit", id: EDIT_ITEM },
    {
      label: "Force Rebuild",

      disabled: currentGroup?.id && !currentGroup?.isContributor2Group,
      id: FORCE_BUILD,
    },
    { label: "Restart", onClick: onRestart },
    { label: "View Json", id: JSON_ITEM },
    { label: "View Logs", id: AUDIT_LOGS },
    {
      label: "Delete",
      id: DELETE_ITEM,
      disabled: currentGroup?.id && !currentGroup?.isContributor2Group,
    },
  ];

  const onDelete = () => {
    deleteApplication(data?.parent?.metadata?.uid, (result, err) => {
      if (!err) {
        navigate("/");
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdateTable = () => {
    handleClose();
  };

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case AUDIT_LOGS:
      title = t.events;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    case EDIT_ITEM:
      title = t.edit(t.application);
      content = (
        <ConfigureBuild data={form} {...{ handleClose, triggerUpdateTable, currentUser }} />
      );
      onConfirmModalAction = onSave;
      break;
    case DELETE_ITEM:
      title = t.delete(t.image);
      onConfirmModalAction = onDelete;
      saveLabel = t.confirm;
      break;
    default:
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        handleClose={handleClose}
        closeLabel={t.close}
        title={title}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
        specificDetails={
          pressedBtn === EDIT_ITEM && {
            shouldHideFooter: true,
            style: {
              contentModal: { height: { xs: "100%", md: "50vh", lg: "70vh" } },
            },
          }
        }
      />

      <Menu
        sx={{ "& .MuiList-padding": { padding: "0.5rem" } }}
        anchorEl={anchorEl[0]}
        open={Boolean(anchorEl[0])}
        onClose={() => onToggleMenuItem(null, 0)}
      >
        {menuMore.map((elem, elemIdx) => {
          return (
            <MenuItem
              sx={{ ...styleTableMenuOption }}
              key={elemIdx}
              id={elem.id}
              onClick={(evt) => {
                onToggleMenuItem(null, 0);
                elem?.onClick ? elem.onClick() : onOpen(evt, data?.parent);
              }}
            >
              {elem.label}
            </MenuItem>
          );
        })}
      </Menu>

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 3em",
          background: "#fff",
          borderRadius: "5px",
          boxShadow:
            "0px 2px 10px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          padding: "0.5rem",
          margin: "0 0 1rem 0",
        }}
      >
        <Box
          style={{
            display: "grid",
          }}
        >
          {[
            {
              label: "Application Name",
              value: data?.parent?.metadata?.displayName,
            },
            ...(data?.parent?.status?.latestImage
              ? [
                  {
                    label: t.latestImage,
                    value: data?.parent?.status?.latestImage,
                    specificDetails: { hasCopy: true },
                  },
                ]
              : []),
            {
              label: "Source Code URL",
              value: data?.parent?.spec?.source?.git?.url,
              specificDetails: { hasCopy: true, hasUrl: true },
            },
            {
              label: "Latest Build Status",
              value: (
                <span
                  style={{
                    ...styleStatus(
                      data?.parent?.status?.conditions?.[0]?.status === FALSE
                    ),
                  }}
                >
                  {data?.parent?.status?.conditions?.[0]?.status}
                </span>
              ),
            },
          ].map((el, elIdx) => (
            <span key={elIdx}>
              <span
                style={{
                  marginRight: "0.2rem",
                  fontSize: "0.696429rem",
                  lineHeight: "1.66",
                }}
              >
                {el.label}:
              </span>
              <span style={{ fontSize: "0.8125rem", lineHeight: "1.43" }}>
                {!el.specificDetails?.hasUrl ? (
                  el.value
                ) : (
                  <a href={el.value} target="_blank" rel="noopener noreferrer">
                    {el.value}
                  </a>
                )}
              </span>
              <IconButton
                onClick={() =>
                  copy2Clipboard({
                    value: el?.value,
                    elIdx,
                  })
                }
              >
                {el.specificDetails?.hasCopy &&
                  (copied.status && copied.elIdx === elIdx ? (
                    <Copied />
                  ) : (
                    <Copy />
                  ))}
              </IconButton>
            </span>
          ))}
        </Box>
        <IconButton
          style={{ height: "2em", width: "2em" }}
          onClick={(evt) => onToggleMenuItem(evt, 0)}
        >
          <MoreHoriz sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Box>
      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => false}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(ImageDetails);
