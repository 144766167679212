import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import { Box, Button, IconButton, MenuItem, Tab, Tabs } from "@mui/material";

import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Refresh as RefreshIcon, MoreHoriz } from "@mui/icons-material";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../helpers/en";
import { JSON_ITEM, FALSE } from "../helpers/constants";
import {
  getImages,
  getMetrics,
  restartService,
  getServiceInstanceLogs,
} from "../helpers/utils";
import { filterRecords, convert2MB } from "../helpers/selectors";
import usePopup from "../helpers/contexts/usePopup";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import ConfigureBuild from "./ConfigureBuild";
import PopupWindow from "../components/PopupWindow";

import {
  styleEllipsis,
  styleTableMenuOption,
  styleStatus,
  styleButton,
} from "../GeneralStyles";

const ADD = "ADD";
const INITIAL_TABLE_DATA = {
  data: [],
  filtered: [],
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const rowsParams = (data, tab) => ({
  header:
    tab === 0
      ? [
          t.name,
          t.latestImage,
          t.git,
          t.buildCounter,
          t.latestBuildReason,
          t.status,
          t.version,
          t.createdOn,
        ]
      : [
          t.name,
          t.url,
          t.status,
          t.statusMsg,
          t.createdOn,
          "Pods",
          "CPU (%)",
          "Memory (MB)",
        ],
  body: data,
  bodyCount: data?.length ? data : [],

  bodyParams: (el) => {
    const val = tab === 0 ? el : el?.service;

    return tab === 0
      ? [
          { value: val?.metadata?.displayName },
          {
            value: val?.status?.latestImage,
            specialDetails: {
              hasTooltip: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          {
            value: val?.spec?.source?.git?.url,
            specialDetails: {
              hasTooltip: true,
              isUrl: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          { value: val?.status?.buildCounter },
          { value: val?.status?.latestBuildReason },
          {
            value: (
              <span
                style={{
                  ...styleStatus(
                    val?.status?.conditions?.[0]?.status === FALSE
                  ),
                }}
              >
                {val?.status?.conditions?.[0]?.status}
              </span>
            ),
          },
          { value: val?.metadata?.resourceVersion },
          { value: val?.metadata?.creationTimestamp },
        ]
      : [
          {
            value: val?.metadata?.displayName ?? val?.metadata?.name,
          },
          {
            value: val?.status?.url,
            specialDetails: {
              hasTooltip: true,
              isUrl: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          {
            value: val?.status?.conditions?.[0]?.status,
          },
          {
            value: val?.status?.conditions?.[0]?.message,
            specialDetails: {
              hasTooltip: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          { value: val?.metadata?.creationTimestamp },
          { value: el?.pods },
          {
            value: el?.cpu
              ? Math.round((el?.cpu + Number.EPSILON) * 100) / 100
              : 0,
          },
          { value: el?.memory ? convert2MB(el?.memory) : 0 },
        ];
  },
});

const ManageApps = (props) => {
  const { showError, currentUser } = props;

  const [data, setData] = useState(INITIAL_TABLE_DATA);
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pressedBtn, setPressedBtn] = useState(null);
  const [tab, setTab] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [file, setFileData] = useState();
  const { currentGroup } = useSharedProps();
  const {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    onMouseLeave,
    openPopupForItem,
  } = usePopup(100);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setData(INITIAL_TABLE_DATA);
    setTab(newValue);
  };

  const fetchMetrics = (cb, onError) => {
    getMetrics(null, (result, err) => {
      if (!err) {
        setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      if (tab === 0) {
        getImages(null, (result, err) => {
          if (!err) {
            setData({ data: result, filtered: result });

            cb && cb(result);
          } else {
            showError(err);
          }
        });
      } else if (tab === 1) {
        fetchMetrics(cb, showError);
      }
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [showError, tab, currentGroup, refreshKey]);

  useEffect(() => {
    let interval;

    if (tab === 1) {
      interval = setInterval(() => {
        fetchMetrics(null, showError);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [showError, tab, currentGroup]);

  const onOpen = async (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    const promisify = (fn, params) => {
      return new Promise((resolve, reject) => {
        fn(params, (result, err) => {
          if (err) reject(err);
          else resolve(result);
        });
      });
    };

    try {
      if (id === JSON_ITEM) {
        const result = await promisify(getServiceInstanceLogs, {
          name: row?.service?.metadata?.name,
        });

        modalData = result;
      } else {
        modalData = { ...(row ? { row } : {}) };
      }

      setForm(modalData);
    } catch (err) {
      showError(err);
    } finally {
      setPressedBtn(id);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const triggerUpdateTable = () => {
    onRefresh();
    handleClose();
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const onRestart = () => {
    const name = openPopupForItem?.service?.metadata?.name;

    restartService(name, (result, err) => {
      if (err) {
        showError(err);
      }
    });
  };

  const onClose = () => {
    setOpen(false);
    setPressedBtn(null);
    setForm({});
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const body = JSON.parse(e.target.result);
        setFileData(body);
        setPressedBtn(ADD);
        setOpen(true);
      } catch (error) {
        showError("Invalid JSON file");
      }
    };

    reader.readAsText(file);
  };

  const handleFileChange = (body) => {
    const files = body.target.files;
    if (files.length > 0) {
      handleFileUpload(files[0]);
    }
  };
  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.instanceLogs;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={form}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case ADD:
      title = t.add(t.application);
      content = (
        <ConfigureBuild
          data={{ ...(file ?? {}), isEditForm: true, isSetJson: true }}
          {...{ triggerUpdateTable, handleClose, currentUser }}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        title={title}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "1.5rem" }}>
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
          <Tab label={t.build} />
          <Tab label={t.run} />
        </Tabs>
      </Box>
      {[
        { id: 0, specificDetails: { hasBtn: true, hasForceUpdate: true } },
        { id: 1 },
      ].map((it) => {
        return (
          <div
            key={it.id}
            role="tabpanel"
            hidden={tab !== it.id}
            id={`full-width-tabpanel-${it.id}`}
            aria-labelledby={`full-width-tab-${it.id}`}
          >
            {it?.specificDetails?.hasBtn && (
              <>
                <Button
                  sx={{ mb: 2, ...styleButton }}
                  variant="contained"
                  id={ADD}
                  onClick={onOpen}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  {t.addNew}
                </Button>
                <Button
                  sx={{
                    mb: 2,
                    ...styleButton,
                    ...(tab === 0 ? { ml: 2 } : {}),
                  }}
                  component="label"
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  {t.uploadJson}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                </Button>
              </>
            )}
            <Button
              sx={{ mb: 2, ...styleButton, ...(tab === 0 ? { ml: 2 } : {}) }}
              variant="contained"
              onClick={onRefresh}
              startIcon={<RefreshIcon />}
            >
              {t.refresh}
            </Button>
            <Table
              rows={{
                ...rowsParams(data?.filtered, tab),
                body: data?.filtered?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
              }}
              selectedRow={(item) => {
                return false;
              }}
              {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
              searchedValue={filters?.searchedValue}
              {...{
                page,
                rowsPerPage,
                onChangePage,
                onChangeRowsPerPage,
                isLoading,
              }}
              actionBtns={(item) => (
                <IconButton
                  ref={buttonRef}
                  onClick={(e) => {
                    e.stopPropagation();

                    openPopup(item, e.currentTarget);
                  }}
                >
                  <MoreHoriz
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </IconButton>
              )}
            />
          </div>
        );
      })}

      {openPopupForItem && (
        <div ref={popupRef} onMouseEnter={() => {}} {...{ onMouseLeave }}>
          <PopupWindow
            specificDetails={{
              onClose: closePopup,
              popupPosition: popupPosition,
              style: { div: { width: "140px" } },
              actions: [
                ...(tab === 0
                  ? [
                      {
                        label: t.open(t.buildImage),
                        onClick: () =>
                          navigate(
                            `/image/${openPopupForItem?.metadata?.name}`
                          ),
                      },
                    ]
                  : [
                      { label: "Restart", onClick: onRestart },
                      {
                        id: JSON_ITEM,
                        label: t.instanceLogs,
                        onClick: (evt) => onOpen(evt, openPopupForItem),
                      },
                    ]),
              ]?.map((item, idx) => (
                <MenuItem
                  sx={{ ...styleTableMenuOption }}
                  id={item.id}
                  key={idx}
                  onClick={(evt) => {
                    if (item.onClick) {
                      item.onClick(evt);
                      closePopup();
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              )),
            }}
          />
        </div>
      )}

      <Modal
        {...{ open, title, content }}
        closeLabel={t.close}
        handleClose={onClose}
        specificDetails={
          pressedBtn === ADD && {
            shouldHideFooter: true,
            style: {
              contentModal: { height: { xs: "100%", md: "50vh", lg: "70vh" } },
            },
          }
        }
      />
    </Box>
  );
};

export default withErrorHandling(ManageApps);
