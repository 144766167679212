import React, { useEffect, useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";

import { Box, ListItemButton } from "@mui/material";

import { SharedPropsProvider } from "../../HOC/SharedProps";

import usePopup from "../../helpers/contexts/usePopup";

import AppBar from "../../components/AppBarMain";
import DrawerMain from "../../components/DrawerMain";
import MainListItems from "./NavItems";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Footer from "../../components/Footer/Footer";
import PopupWindow from "../../components/PopupWindow";

export default function Main(props) {
  const { onLogout, currentUser, navItems } = props;

  const [open, setOpen] = useState(true);
  const [toggledMenuList, setToggledMenuList] = useState({
    list1: true,
    list2: false,
  });
  const [viewport, setViewport] = useState(window.innerWidth);

  const {
    buttonRef,
    popupRef,
    popupPosition,
    openPopup,
    closePopup,
    onMouseLeave,
    openPopupForItem,
  } = usePopup(80);
  const location = useLocation();

  const isSmallScreen = viewport < 1200;

  useEffect(() => {
    const onResize = () => {
      setViewport(window.innerWidth);
      closePopup();
    };

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [closePopup]);

  const onToggleDrawer = () => {
    setOpen(!open);
  };

  const onToggleMenuList = (listName) => {
    setToggledMenuList((prev) => ({
      ...prev,
      [listName]: !prev[listName],
    }));
  };

  return (
    <SharedPropsProvider>
      <AppBar
        {...{
          onLogout,
          currentUser,
          open,
          onToggleDrawer,
          viewport,
        }}
      />
      <Box style={{ display: "flex" }}>
        <DrawerMain
          {...{ open, onToggleDrawer, viewport }}
          menuList={
            <MainListItems
              {...{
                navItems,
                viewport,
                open,
                onToggleMenuList,
                toggledMenuList,
                openPopup,
                buttonRef,
              }}
            />
          }
        />

        {!isSmallScreen && openPopupForItem && (
          <div ref={popupRef} onMouseEnter={() => {}} {...{ onMouseLeave }}>
            <PopupWindow
              specificDetails={{
                onClose: closePopup,
                popupPosition: popupPosition,
                style: { div: { width: "160px" } },
                actions: openPopupForItem.subItems?.map((subItem, itemIdx) => (
                  <ListItemButton
                    key={itemIdx}
                    component={NavLink}
                    sx={{
                      fontSize: "13px",
                      ...(location.pathname === subItem.to
                        ? {
                            background: (theme) =>
                              theme.palette.primary.activeLink,
                            color: (theme) => theme.palette.primary.text,
                          }
                        : {}),
                    }}
                    onClick={closePopup}
                    to={subItem.to}
                    role="menuitem"
                    aria-label={`Navigate to ${subItem.label}`}
                    disableRipple
                  >
                    {subItem.label}
                  </ListItemButton>
                )),
              }}
            />
          </div>
        )}

        <main
          id="main"
          style={{
            maxWidth: "2500px",
            margin: "1rem",
            background: "none !important",
            ...(!isSmallScreen
              ? { marginLeft: open ? 316 : 116 }
              : { marginLeft: "1rem" }),
            transition: "margin-left 0.3s",
            width: !isSmallScreen
              ? open
                ? "calc(100% - 316px - 1rem)"
                : "calc(100% - 116px - 1rem)"
              : "calc(100% - 2rem)",
          }}
        >
          <Breadcrumbs />

          <Outlet />

          <Footer />
        </main>
      </Box>
    </SharedPropsProvider>
  );
}
