import * as React from "react";

import { Close } from "@mui/icons-material";

import { Box, Button, IconButton, Modal } from "@mui/material";

const style = (item) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  ...(item ? { width: { xs: "90vw", md: "50em", lg: "70em" } } : {}),
  bgcolor: "background.paper",
  borderRadius: "5px",
  margin: "0px 0px 0.35em",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 1.25,
  boxShadow: 24,
  overflow: "auto",
  maxHeight: "95vh",
});
const styleButton = {
  width: { md: "15em" },
  padding: "0.485rem",
  fontSize: "13px",
  textTransform: "none",
};

const margins = { pt: 2, px: 4, pb: 3 };

export default function ModalDialog(props) {
  const {
    open,
    handleClose,
    title,
    content,
    closeLabel,
    onSave,
    saveLabel,
    specificDetails,
  } = props;

  const showSave = saveLabel || false;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style(content) }}>
        <Box
          sx={{
            background: "aliceblue",
            position: "sticky",
            top: 0,
            zIndex: 10,
          }}
        >
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1rem 2rem",
              margin: 0,
            }}
            id="child-modal-title"
          >
            <span style={{ display: "flex", width: "100%" }}>{title}</span>
            {handleClose && (
              <IconButton onClick={handleClose}>
                <Close
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    padding: "0 !important",
                  }}
                />
              </IconButton>
            )}
          </h2>
        </Box>
        <Box sx={{ ...margins, ...(specificDetails?.style?.contentModal || []) }}>
          {content}
        </Box>
        {!specificDetails?.shouldHideFooter && (handleClose || showSave) && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridColumnGap: "1rem",
              position: "sticky",
              bottom: 0,
              background: "#fff",
              padding: "1rem",
              zIndex: 10,
              ...margins,
            }}
          >
            {handleClose && (
              <Button
                sx={{ ...styleButton }}
                variant="outlined"
                onClick={handleClose}
              >
                {closeLabel}
              </Button>
            )}
            {showSave && (
              <Button
                sx={{ marginLeft: "auto", ...styleButton }}
                variant="contained"
                onClick={onSave}
              >
                {saveLabel}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
