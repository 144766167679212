export const styleIconBtn = {
  background: (theme) => theme.palette.primary.main,
  borderRadius: "0.5rem",
  color: "#fff",
  "&:hover": {
    background: (theme) => theme.palette.primary.main,
    opacity: 0.7,
  },
};

export const styleInputsWrapper = (items) => ({
  display: { xs: "contents", md: "grid" },
  gridTemplateColumns: "" + items?.map((it) => "1fr").join(" ") + "",
  gridColumnGap: "0.5rem",
});

export const styleEllipsis = {
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const styleTableMenuOption = {
  color: (theme) => theme.palette.primary.main,
  fontSize: "13px",
  paddingLeft: 0,
};

export const styleStatus = (isFalse) => ({
  padding: "0 5px",
  borderRadius: "6px",
  color: "#fff",
  background: isFalse ? "rgb(244, 67, 54)" : "rgb(76, 175, 80)",
});

export const styleButton = {
  fontWeight: 500,
  fontSize: "0.8125rem",
  lineHeight: "1.75",
  textTransform: "none !important",
};
