import { useMatches, useParams } from "react-router-dom";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Breadcrumbs } from "@mui/material";

export default function BreadcrumbsList() {
  const matches = useMatches();
  const params = useParams();
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <Breadcrumbs
      sx={{ display: "flex", mb: 2 }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {crumbs.map((crumb, idx) => {
        const currentCrumb = params?.id
          ? {
              ...crumb,
              props: {
                ...crumb.props,
                to: crumb.props.to.replace(":id", params?.id),
                ...(crumb.props.to?.includes(":id")
                  ? { children: `${crumb.props.children} ${params?.id}` }
                  : {}),
              },
            }
          : crumb;

        return (
          <span style={{ fontSize: "13px" }} key={idx}>
            {currentCrumb}
          </span>
        );
      })}
    </Breadcrumbs>
  );
}
